import React from 'react';

import logo from 'src/assets/images/anage-select.svg';
//import logoMobile from 'src/assets/images/anage-select.svg';

function Logo(props) {
        //let mobileSize = window.innerWidth < 768;

        return (
                <img
                        alt="Logo"
                        src={logo}
                        {...props}
                />
        );
}

export default Logo;
