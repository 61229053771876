import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import Blog from 'src/components/AnageImoveis/Blog';
import image from 'src/assets/images/conheca-joinville.webp';
import image2 from 'src/assets/images/conheca-joinville2.webp';
import image3 from 'src/assets/images/conheca-joinville3.webp';
import image4 from 'src/assets/images/conheca-joinville4.webp';

import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {}
}));

function City({ match, ...rest }) {
    const classes = useStyles();

    let pageTitle = 'Joinville';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    useEffect(() => {
        let pageContent = window;
        let scrollTo = document.getElementById(match.params.neighbourhood);
        if(pageContent && scrollTo) {
            pageContent.scrollTo(0,scrollTo.offsetTop-100);
        }
    }, [match.params.neighbourhood]);

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="city-content">
                <div className="info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="description">
                                    <h1 className="title title-1">
                                        Joinville é toda encantos
                                    </h1>
                                    <p className="text text-1">
                                        A gente caminha pela Beira-Rio aos domingos e vê a festa das famílias na rua que se transforma em um grande passeio. Anda um pouquinho mais e pode até ver as meninas e meninos do Bolshoi, a mais famosa escola de balé do mundo. Por ali mesmo, do mirante, a gente vê a Babitonga, baía atlântica, afinal, estamos pertinho do mar. E dali para a via gastronômica é um pulo: para quem traz nas origens as receitas de suíços, alemães, italianos, imagine só. Joinville é uma delícia!
                                    </p>
                                    <p className="text text-1">
                                        Além disso, o acesso às praias catarinenses e paranaenses, para o campo ou para Curitiba é muito fácil! É a maior economia do estado, tem aeroporto, shoppings, universidades, escolas trilíngues, indústrias. É também a maior cidade do estado, com jeito de interior, mas na dianteira das inovações: Joinville é um polo tecnológico e tem o maior parque empresarial multissetorial da América do Sul. Venha viver Joinville, a cidade sabe receber as pessoas, e muito bem!
                                    </p>
                                    <RouterLink to="/alugar/joinville" className="btn btn-6 hidden-mobile">Imóveis a venda</RouterLink>
                                    <RouterLink to="/comprar/joinville" className="btn btn-6 hidden-mobile ml-1">Imóveis para alugar</RouterLink>
                                </div>
                            </div>
                            <div className="col-md-6 offset-md-1 hidden-mobile">
                                <div className="image-group">
                                    <img alt="" src={image4} className="image image-1 img-fluid"></img>
                                    <img alt="" src={image3} className="image image-2 img-fluid"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="schedule hidden-mobile">
                    <div className="container">
                        <div className="item">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="title title-2">
                                        Dados de Joinville
                                    </h3>
                                    <p className="text text-1">
                                        Localizada na região Sul do País, município polo da microrregião nordeste de Santa Catarina. Concentra grande parte da atividade econômica na indústria.
                                    </p>
                                </div>
                                <div className="col-md-5 offset-md-1">
                                    <p className="text text-1">
                                        <span>Fundação:</span> 9 de março de 1851
                                    </p>
                                    <p className="text text-1">
                                        <span>População:</span> aprox. 590 mil hab.
                                    </p>
                                    <p className="text text-1">
                                        <span>Densidade demográfica:</span> 530,7 hab./km²
                                    </p>
                                    <p className="text text-1">
                                        <span>Área:</span> 1.125,70 km²
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="images-block">
                    <div className="image" style={{ backgroundImage: 'url(' + image + ')' }}></div>
                    <div className="image" style={{ backgroundImage: 'url(' + image2 + ')' }}></div>
                </div>
                <div className="districts">
                    <div className="container">

                        <h2 className="title title-1">
                            Conheça os bairros de Joinville
                        </h2>

                        <div className="box" id="america">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            América
                                        </h3>
                                        <p className="text text-1">
                                            O América, às vezes, se confunde com o Centro de Joinville. É um grande bairro: estão situados ali a Escola do Teatro Bolshoi no Brasil, o Centreventos Cau Hansen, o Teatro e Instituto Juarez Machado. América é nome de clube - um dos maiores campeões de futebol do estado - e também é o bairro do MAJ (Museu de Arte de Joinville), da Cidadela Cultural Antarctica, do Museu Memorial, das praças e parques arborizados. O bairro abriga ruas tranquilas, com prédios e casas de alto padrão. Viva o América!
                                        </p>
                                        <RouterLink to="/comprar/joinville//america" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/america" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 1,63 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> shoppings, supermercados, academias, farmácias, bancos, concessionárias, laboratório e hospitais, escolas e faculdades.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="anita-garibaldi">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Anita Garibaldi
                                        </h3>
                                        <p className="text text-1">
                                            O Anita Garibaldi é o bairro mais querido da cidade. Também, tem de tudo! A vida é mais doce na Doceria São José, está colado na Via Gastronômica, um pulo e já se está no Centro e se é o caso de viajar na história, a Estação da Memória é um passeio e tanto. O Anita é bonito, tranquilo, seguro, bem arborizado e ainda se vê casinhas no estilo enxaimel, registros inconfundíveis da arquitetura alemã do renascimento, herança dos primeiros imigrantes. Está aí um lugar para viver com muito charme e qualidade!
                                        </p>
                                        <RouterLink to="/comprar/joinville//anita-garibaldi" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/anita-garibaldi" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 2,04 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> shopping, supermercados, hospital, maternidade, farmácias, banco, concessionária, academia, Rodoviária de Joinville, escolas, faculdades, parque.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="atiradores">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Atiradores
                                        </h3>
                                        <p className="text text-1">
                                            O nome é só memória dos antigos clubes alemães: em Joinville ainda vivem o gosto pelas flores e os jardins bem arrumados e a paixão pelo desenvolvimento organizado. Esse é o bairro da Via Gastronômica, tradicional e tranquilo, com infraestrutura fantástica. É o mais nobre dos nobres de Joinville! Tem de tudo no Atiradores, vizinho do Centro e uma das regiões mais valorizadas e arborizadas da cidade. É a região dos edifícios de Joinville, mas é também a mais plana e a da bem frequentada pista de caminhada. O Atiradores é um sonho de morar bem!
                                        </p>
                                        <RouterLink to="/comprar/joinville//atiradores" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/atiradores" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 2,05 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> farmácias, academia, colégios, faculdade. Próximo de shoppings.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="boa-vista">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Boa Vista
                                        </h3>
                                        <p className="text text-1">
                                            O Boa Vista tem a sua vida ligada à Fundição Tupy e à vocação industrial de Joinville. Além disso, é moderno e tem de tudo, sem perder contato com a natureza. Começa colado com o Centro e a 2 km dali já tem o Morro da Boa Vista – 390 hectares de mata atlântica preservada e dois mirantes com vista para a cidade e a Serra do Mar. Um dos principais pontos turísticos de Joinville. Agora, quer fazer um passeio à moda europeia? Então você precisa conhecer o Joinville Iate Clube, que fica no Boa Vista. Esse bairro vai morar no seu coração!
                                        </p>
                                        <RouterLink to="/comprar/joinville//boa-vista" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/boa-vista" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 2,47 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> supermercados, hospital, farmácias, escolas, universidade, bancos, Terminal Tupy, museu.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="bom-retiro">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Bom Retiro  
                                        </h3>
                                        <p className="text text-1">
                                            Esse é um bairro privilegiado, que abriga o Parque Municipal Morro do Finder. São 50.000 m² de natureza exuberante, trilhas, nascentes, lagos, gruta. De manhã dá pra subir ao mirante e ver as águas prateadas da Baía da Babitonga. É um convite ao bem-estar. Está pertinho das universidades e do Garten Shopping. E num instante você está no Centro. O Bom Retiro já foi conhecido como a Estrada da Serra - da Serra Dona Francisca, a princesa que quase veio morar por aqui com seu príncipe. É isso mesmo, o Bom Retiro é um lugar para viver feito reis!
                                        </p>
                                        <RouterLink to="/comprar/joinville//bom-retiro" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/bom-retiro" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 4,57 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> supermercados, escolas, farmácia e praças. Próximo a shopping e universidades.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="bucarein">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Bucarein
                                        </h3>
                                        <p className="text text-1">
                                            Tem a Dr. João Colin que vira JK, que vira Getúlio Vargas, que vira Santa Catarina: pronto, você chegou ao Bucarein! É a casa do Joinville Esporte Clube, a Arena Joinville. Agora, se para ir ao Bucarein as ruas vão mudando de nome, do Bucarein é muito fácil chegar a todo lugar: o Mercado Público Municipal, o Parque da Cidade, a Praça da Liberdade, a Estação da Memória, os shoppings. É também região de largas avenidas, pontes e passagens bem planejadas. Mas, as áreas residenciais são tranquilas. Bucarein é um lugar para viver bem e calmamente.
                                        </p>
                                        <RouterLink to="/comprar/joinville//bucarein" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/bucarein" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 1,61 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> academia, restaurantes, supermercado, bancos, lojas, farmácias, Sesi, escolas, universidade, praça.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="centro">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Centro
                                        </h3>
                                        <p className="text text-1">
                                            Tudo começou ali, onde hoje é a Praça Lauro Muller, marco zero de Joinville. A princesa Dona Francisca, filha de D. Pedro I, viria com o príncipe de Joinville, Francisco d'Orléans. Não vieram, mas o palácio já estava pronto e hoje é o Museu Nacional da Imigração, obra belíssima, diante da Rua das Palmeiras. Na região se destacam prédios modernos e casas e palacetes coloniais preservados. O Centro também guarda intactas as sociedades alemãs e o Museu do Sambaqui, que preserva a memória indígena. Aqui tem história, cultura e descobertas!
                                        </p>
                                        <RouterLink to="/comprar/joinville//centro" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/centro" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> bancos, farmácia, colégios, faculdade, clínica, shopping, confeitaria, Terminal Urbano Central, concessionária, hospital, Mercado Municipal, museus, Biblioteca Pública Municipal, camelódromo e Catedral de Joinville.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="costa-e-silva">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Costa e Silva
                                        </h3>
                                        <p className="text text-1">
                                            O Costa e Silva começou pequenininho, em 1969, com o nome de Vila Comasa. Mudou de nome para Costa e Silva em 1977 e ganhou uma infraestrutura invejável: tem de tudo. É um bairro nobre. É no Costa e Silva que estão algumas das nascentes preservadas do rio Cachoeira, que lá no Centro dá origem à famosa Beira-Rio. O Costa e Silva tem indústrias, mas também tem parques e praças. Só o que pode ser difícil para quem vai morar no Costa e Silva é pronunciar o nome da rua “Otto Pfuetzenreuter”. Mas, com o tempo, você aprende. E vai valer muito a pena!
                                        </p>
                                        <RouterLink to="/comprar/joinville//costa-e-silva" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/costa-e-silva" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 4,40 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> supermercado, bancos, academias, farmácias, escolas, faculdade, concessionária, unidade de saúde e pronto-atendimento.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="distrito-industrial-norte">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Distrito Industrial Norte
                                        </h3>
                                        <p className="text text-1">
                                            Indústrias bem instaladas, com toda a infraestrutura, distante da malha urbana, mas com todas as facilidades de acesso e expansão. Esse é o Distrito Industrial Norte de Joinville. É fácil ir para o Centro e seguir para o sul do Estado ou para as cidades ao norte. Próximo ao bairro fica o maior parque empresarial multissetorial da América do Sul: são 38 indústrias instaladas, além do Ágora Tech Park - um hub de inovação - em 1.100 hectares. A área é responsável por um número de empregos importante para o desenvolvimento local. E isso é desde sempre.
                                        </p>
                                        <RouterLink to="/comprar/joinville//distrito-industrial-norte" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/distrito-industrial-norte" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 7,03 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> aeroporto, parque e kartódromo, supermercado, casa de eventos, shopping, cervejaria, Senai, universidades e Ceasa.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="floresta">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Floresta
                                        </h3>
                                        <p className="text text-1">
                                            Como já aconteceu muitas vezes em Joinville, um time de futebol deu nome ao bairro. E por que floresta? A região era coberta por uma densa mata. O Floresta também foi conhecido como Katharinenstrasse. A tradução é simples: Estrada Santa Catarina – foi importantíssima para o desenvolvimento da Colônia Dona Francisca, primeiro nome de Joinville. Nas placas de rua você vai encontrar o nome atual e o antigo, geralmente em alemão. O bairro é tradicional, mas é moderno também e com uma infraestrutura invejável. Excelente lugar para morar bem!
                                        </p>
                                        <RouterLink to="/comprar/joinville//floresta" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/floresta" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Sul
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 3,47 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> bancos, supermercados, cervejaria, escolas, Terminal Sul Vera Cruz, sociedade esportiva e recreativa, casa de festas e eventos, praças e áreas de lazer.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="gloria">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Glória
                                        </h3>
                                        <p className="text text-1">
                                            Esse é mais um bairro de Joinville que ganhou nome de clube de futebol, em 1928. Bairro de alemães, cujas tradições foram se perpetuando por meio de seus descendentes. É no Glória que estão o Pórtico de Joinville, a principal entrada da cidade, com o moinho que a gente vê de longe; os pavilhões da Expoville e o Megacentro Wittich Freitag, lugar de eventos tradicionais. O bairro é grande e tem tudo o que você precisa. Até para ir às praias é fácil, a BR-101 está a um passo. A infraestrutura do bairro é excelente. Viver aqui é muito bom!
                                        </p>
                                        <RouterLink to="/comprar/joinville//gloria" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/gloria" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 2,78 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> hospital, unidade de saúde, mercado, floricultura, escolas, universidade, banco, concessionárias, escolas esportivas, academia e Batalhão de Polícia Militar.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="guanabara">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Guanabara
                                        </h3>
                                        <p className="text text-1">
                                            Dizem que o nome vem de um antigo time de futebol da região, mas Guanabara também significa enseada que se parece com o mar. E o meio ambiente no Guanabara ainda tem remanescentes de manguezais do leste joinvilense, bem perto das margens do rio Itaum-Açú e da foz do rio Bucarein; e tem os sambaquis nos sítios arqueológicos. Como se vê, o Guanabara tem suas histórias, mas também tem áreas de lazer e está pertinho do Parque da Cidade. É a porta de entrada da zona Sul e tem a cara de Joinville: no centrinho tem de tudo e quem mora aqui adora o lugar!
                                        </p>
                                        <RouterLink to="/comprar/joinville//guanabara" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/guanabara" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Sudeste
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 2,85 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> Terminal Urbano de Ônibus Guanabara, unidade de saúde, escola e supermercados.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="iririu">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Iririú
                                        </h3>
                                        <p className="text text-1">
                                            Iririú é rio da ostra em tupi-guarani. Na região passa o iririú-mirim, que deságua na Baía da Babitonga. Já foi região de mangue e densa mata, mas o progresso foi chegando e o Iririú se transformou, com comércio intenso. Uma parte é o Morro do Iririú, outra parte é o Morro do Boa Vista e, ainda, o Parque Municipal Morro do Finder, o quintal da casa de quem mora no Iririú! Com a construção do binário, ir e vir ganhou mobilidade. É bonito ver, dos lugares mais altos, o fio de prata do mar lá no horizonte. Quer um lugar bom para morar em Joinville? Iririú.
                                        </p>
                                        <RouterLink to="/comprar/joinville//iririu" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/iririu" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Leste
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 3,83 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> unidade de saúde, academia, bancos, farmácias, escolas, faculdade, associação atlética, Fundação Pró-Rim.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="pirabeiraba">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Pirabeiraba
                                        </h3>
                                        <p className="text text-1">
                                            Pirabeiraba é peixe brilhante em tupi-guarani. Bonito, né! Mas bonito mesmo é ver o que Pirabeiraba tem pra mostrar. Do lado de lá ou do lado de cá da BR-101 a natureza é de tirar o fôlego. Imigrantes europeus foram chegando a partir do século XIX e hoje ainda tem umas casinhas desse período na estrada Dona Francisca. Pirabeiraba tem ar rural, no Centro não tem sinaleiros nem lombadas, bucólica que só! Mas é autossuficiente, é mais que um bairro e já se tornou moderníssima nos condomínios fechados e na qualidade de vida de quem escolheu “Pira” para viver.
                                        </p>
                                        <RouterLink to="/comprar/joinville//pirabeiraba" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/pirabeiraba" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro de Joinville:</span> 11,42 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> unidade de saúde, hospital, bancos, mercado e supermercados, Terminal de Ônibus de Pirabeiraba e sociedade cultural e esportiva. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="saguacu">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Saguaçu
                                        </h3>
                                        <p className="text text-1">
                                            Ainda são poucos os prédios, o que dá ao bairro um ar calmo, familiar. Por outro lado, do Mirante de Joinville, que fica no Saguaçu, você vai acabar descobrindo a cidade e o verde da mata que vai ladeando a Baía da Babitonga, que é muito linda! Ali do ladinho aproveita para curtir o Zoobotânico, passear no parque e pegar uma trilha. Por ali passa um riozinho, o Saguaçu, que desemboca no Cachoeira. Um pouco mais em direção ao Centro tem o Arquivo Histórico da Cidade e a Casa da Cultura. Já deu pra perceber? Esse é o bairro! Venha conhecer.
                                        </p>
                                        <RouterLink to="/comprar/joinville//saguacu" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/saguacu" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 1,95 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> sociedade esportiva e recreativa, restaurante, academia, clube de tênis, hostel, Prefeitura e Câmara de Vereadores de Joinville, Associação Empresarial de Joinville, banco, colégios, faculdade e concessionária.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="santo-antonio">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Santo Antônio
                                        </h3>
                                        <p className="text text-1">
                                            Todo mundo diz que o nome do bairro é por conta da Igreja de Santo Antônio, mas, ela fica mesmo é no Bom Retiro, bairro vizinho. O Santo Antônio já teve outras denominações, fazia parte do que chamavam de Serrastrasse, a Estrada Dona Francisca. É um bairro privilegiado, pois além da sua própria infraestrutura, excelente, também compartilha os recursos dos seus vizinhos Costa e Silva, Bom Retiro, Saguaçu, Centro. O acesso ao polo estudantil ou à Zona Industrial é muito fácil. É um dos melhores lugares para viver em Joinville!
                                        </p>
                                        <RouterLink to="/comprar/joinville//santo-antorio" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/santo-antonio" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Centro/Norte
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 3,65 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> sacolão, supermercados, escolas, academia, grêmio esportivo, farmácia, bancos, concessionária e Terminal Norte.            
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box" id="vila-nova">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="block">
                                        <h3 className="title title-2">
                                            Vila Nova
                                        </h3>
                                        <p className="text text-1">
                                            Se quiser chamar de Neudorf também pode, mas o bairro já mudou de nome faz tempo. Não é só a terra dos arrozais, é colônia de alemães e italianos, que fazem festa para reavivar as tradições no Dia do Colono. Se combinar direitinho, um colono ainda leva na sua casa os ovos e as verduras das chacrinhas que tem por ali. Tem rios bons pra pescar também e a usina do Piraí, um dos pontos turísticos mais conhecidos da região. E tem a paz de um lugar excelente para viver, com a calma do interior e as facilidades de um bairro completo, com tudo perto! 
                                        </p>
                                        <RouterLink to="/comprar/joinville//vila-nova" className="btn btn-1">Imóveis a venda</RouterLink>
                                        <RouterLink to="/alugar/joinville/vila-nova" className="btn btn-1">Imóveis para alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="block">
                                        <h4 className="title title-3">
                                            Região Oeste
                                        </h4>
                                        <p className="text text-1">
                                            <span>Distância do Centro:</span> 6,38 km
                                        </p>
                                        <p className="text text-1">
                                            <span>Infraestrutura:</span> escolas, Sesc Comunidade Joinville, Fundação Municipal 25 de Julho, supermercados, bancos, Terminal de Ônibus Vila Nova.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Blog />
            </div>
            <Newsletter />
        </Page>
    );
}

export default City;
