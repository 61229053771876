import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import Page from 'src/components/Page';
import Post from 'src/views/pages/Blog/Post/Post';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import axios from 'src/utils/apiWP';

function Article({ match, history }) {
    const dispatch = useDispatch();
    const [slug, setSlug] = useState(false);
    const [post, setPost] = useState(false);
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [subTitle, setSubTitle] = useState([]);
    
    const fetchRelatedPosts = async () => {
        let requestId = uuidv4();
        
        if (!post)
            return;
        
        if(post.categories && post.categories.length) {
            dispatch(addLoadingGlobal(requestId));
            axios.get(`https://blog.anageimoveis.com.br/wp-json/wp/v2/posts?_embed&per_page=3&categories=${post.categories[0]}`).then((response) => {
                if (response.request.status === 200) {
                    response.data = response.data.filter((relatedPost) => {
                        if(post.id == relatedPost.id) {
                            return false;
                        }
                        return true;
                    })
                    setRelatedPosts(response.data);
                }
            }).catch((error) => {
                console.log(error);
            }).then(() => {
                dispatch(removeLoadingGlobal(requestId));
            });
        }
        
    };

    const handleFilter = (text) => {
        history.push('/blog?busca='+text);
    }

    // const fetchCategoriesById = async (index) => {
    //     const response = await axios.get(`https://blog.anageimoveis.com.br/wp-json/wp/v2/categories/${index}`).then((response) => {
    //         if (response.request.status === 200) {
    //             return response.data;
    //         }
    //     });

    //     return response;
    // };

    const fetchPost = async (path) => {
        const requestId = uuidv4();
        
        if (!path) {
            return;
        }

        dispatch(addLoadingGlobal(requestId));
        axios.get(`https://blog.anageimoveis.com.br/wp-json/wp/v2/posts?slug=${path}`).then((response) => {
            if (response.request.status === 200) {
                setPost(response.data[0]);
            }
        }).catch((error) => {
            console.log(error);
        }).then(() => {
            dispatch(removeLoadingGlobal(requestId));
        });
    };

    useEffect(() => {
        let path = document.location.href;
        if(path[path.length-1] == '/') {
            path = path.substr(0, path.length-1);
        }
        path = path.split('/');
        path = path[path.length-1];
        fetchPost(path);
    }, [match]);

    useEffect(() => {
        fetchRelatedPosts();
    }, [post]);

    if (!post) {
        return <></>
    };

    return (
        <Page
            title={post.id && post.title.rendered}
        >
            <Post
                post={post}
                subTitle={subTitle}
                relatedPosts={relatedPosts}
                onFilter={handleFilter}
            />
            <Newsletter />
        </Page>
    );
}

export default Article;
