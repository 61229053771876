import React, { useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import search from 'src/assets/images/icons/feather-search.svg';
import checkbox from 'src/assets/images/icons/ionic-ios-checkbox-outline.svg';
import imgAd from 'src/assets/images/Grupo4642@2x.png';

export default function AdInfo() {
    return (
        <div className="info-ad">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="description">
                                    <h2 className="title title-10">
                                        O PODER DE REALIZAR EXPERIÊNCIAS
                                    </h2>
                                    <div className="block">
                                        <div className="item">
                                            <img alt="" src={checkbox} className="icon"></img>
                                            <div className="item-info">
                                                <p className="text text-1">Exclusividade no alto padrão</p>
                                                <p className="text text-1">Superamos suas expectativas com as melhores opções de imóveis, nos bairros mais desejados de Joinville.</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <img alt="" src={checkbox} className="icon"></img>
                                            <div className="item-info">
                                                <p className="text text-1">Seriedade nas negociações</p>
                                                <p className="text text-1">Garantimos uma transação segura e valorizamos a discrição e a transparência em todas as etapas.</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <img alt="" src={checkbox} className="icon"></img>
                                            <div className="item-info">
                                                <p className="text text-1">Consultoria em imóveis</p>
                                                <p className="text text-1">Nossa escuta ativa permite que possamos garantir suas exigências e apresentar resultados efetivos.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <RouterLink to="/anuncie-seu-imovel" className="btn btn-1">
                                        <img alt="" src={search} className="icon" />
                                        Encontrar imóveis
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img alt="" src={imgAd} className="img-fluid img-ad"></img>
        </div>
    );
}