import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ErrorMessage, Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { addLoadingGlobal, removeLoadingGlobal, addMessage } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import ClassNewsletter from 'src/models/NewsletterModels';
import { values } from 'lodash';

import { useAnalytics } from 'src/hooks/useAnalytics';

let classNewsletter = new ClassNewsletter();

const defaultFormShape = classNewsletter.getObjects;

export default function Newsletter({}) {
    const dispatch = useDispatch();
    const { trackEvent } = useAnalytics();
    const newsletterApi = new ManagerApi('/contact/newsletter');

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        acceptPolicy: Yup.string().required('Campo obrigatório.')
    });

    return (
        <div className="newsletter-section">
            <div className="container">
                <Formik
                    initialValues={defaultFormShape}
                    enableReinitialize
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values , {resetForm, setStatus}) => {
                        let data = new FormData();
                        data = values;

                        if(!values.acceptPolicy) {
                            dispatch(addMessage('Para prosseguir, você precisa aceitar os termos de envio.', 'error'));
                            return;
                        }

                        dispatch(addLoadingGlobal());
                        newsletterApi.post(data).then(response => {
                            dispatch(removeLoadingGlobal());

                            if (response.data.success == true) {
                                dispatch(addMessage('Registro realizado com sucesso.', 'success'));
                                resetForm();
                                trackEvent('Envio de formulário', 'Newsletter', '')
                            }
                            else {
                                dispatch(addMessage('Não foi possível enviar o contato, por favor tente novamente mais tarde.', 'error'));
                            }
                        });
                    }}
                >
                { ({ handleBlur, handleChange, setFieldValue, status, values }) => (
                    <FormikForm className="form" id="form-anage-newsletter">
                        <div className="form-row">
                            <div className="col-md-6">
                                <div className="info">
                                    <p className="text text-1">
                                        <span className="title title-4">Pensando em alugar ou comprar um imóvel? </span>
                                        Deixe seu e-mail e receba nossas dicas, novidades e promoções!
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-row">
                                    <div className="col-6 col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="newsletter-name" className="label-control">Nome</label>
                                            <Field type="text" className="form-control" id="newsletter-name" onBlur={handleBlur} onChange={handleChange} name="name" placeholder="Nome" />
                                            <ErrorMessage component="span" name="name" className="error-message" />
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="newsletter-email" className="label-control">E-mail</label>
                                            <Field type="email" className="form-control" id="newsletter-email" onBlur={handleBlur} onChange={handleChange} name="email" placeholder="Email" />
                                            <ErrorMessage component="span" name="email" className="error-message" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <button type="submit" name="button" className="btn btn-2 btn-lg">
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-12">
                                <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('acceptPolicy',!values.acceptPolicy) } }>
                                    <input type="checkbox" className="custom-control-input" name="acceptPolicy" checked={values.acceptPolicy} />
                                    <label className="custom-control-label">Ao preencher este formulário concordo com a coleta e tratamento dos meus dados, conforme <RouterLink to="/PolticadePrivacidadeAnage.pdf" target="_blank">Política de Privacidade</RouterLink>, nos termos da Lei 13.709/2018, permitindo desde já eventual armazenamento destes dados e o contato comercial da Anagê Imóveis</label>
                                </div>
                            </div>
                        </div>
                    </FormikForm>
                )}
                </Formik>
            </div>
        </div>

    )

Newsletter.propTypes = {
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
};

}
