import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ErrorMessage, Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { addLoadingGlobal, removeLoadingGlobal, addMessage } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import ClassContact from 'src/models/ContactModels';
import { getWhatsappLink } from 'src/utils/functions';
import HowRentJoinville from 'src/assets/images/comprar-alugar-imovel-em-joinville.webp';
import whatsapp from 'src/assets/images/icons/whatsapp.svg';
import background from 'src/assets/images/topo-fale-com-anage-imoveis.webp';
let classContact = new ClassContact();

const defaultFormShape = classContact.getObjects;

export default function ContactContent({}) {
    const dispatch = useDispatch();
    const { trackEvent } = useAnalytics();
    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const contactApi = new ManagerApi('/contact/contact');

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        phone: Yup.string().required('Campo obrigatório.'),
        // department: Yup.string().required('Campo obrigatório.'),
        message: Yup.string().required('Campo obrigatório.'),
        bestTime: Yup.string().required('Campo obrigatório.'),
    });
    
    const styleSuccess = {
        color:'green',
        textAlign:'center'
    }

    const styleDeny = {
        color:'red',
        textAlign:'center'
    }

    return (
        <>
            <div className="contact-block">
                <h2 className="title title-9">Nós te atendemos</h2>
                <Formik
                    initialValues={defaultFormShape}
                    enableReinitialize
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values , {resetForm, setStatus}) => {
                        let data = new FormData();
                        data = values;

                        if(!values.acceptPolicy) {
                            dispatch(addMessage('Para prosseguir, você precisa aceitar os termos de envio.', 'error'));
                            return;
                        }

                        dispatch(addLoadingGlobal());
                        contactApi.post(data).then(response => {
                            dispatch(removeLoadingGlobal());

                            if (response.data.success == true) {
                                dispatch(addMessage('Registro realizado com sucesso.', 'success'));
                                resetForm();
                                trackEvent('Envio de formulário', 'Contato', '')
                            }
                            else {
                                dispatch(addMessage('Não foi possível enviar o contato, por favor tente novamente mais tarde.', 'error'));
                            }
                        });
                    }}
                >
                    { ({ handleBlur, handleChange, setFieldValue, status, values }) => (
                    <FormikForm className="form">
                        <div className="form-group normal-group">
                            <label htmlFor="contactName" className="label">Nome</label>
                            <Field type="text" className="form-control" id="contactName" onBlur={handleBlur} onChange={handleChange} name="name" placeholder="Digite seu nome" />
                            <ErrorMessage component="span" name="name" className="error-message" />
                        </div>
                        <div className="form-group normal-group">
                            <label htmlFor="contactEmail" className="label">E-mail</label>
                            <Field type="email" className="form-control" id="contactEmail" onBlur={handleBlur} onChange={handleChange} name="email" placeholder="exemplo@email.com.br" />
                            <ErrorMessage component="span" name="email" className="error-message" />
                        </div>
                        <div className="form-group normal-group">
                            <label htmlFor="contactPhone" className="label">DDD + Telefone/WhatsApp</label>
                            <Field type="text" className="form-control" id="contactPhone" onBlur={handleBlur} onChange={handleChange} name="phone" placeholder="(00) 00000-00000" />
                            <ErrorMessage component="span" name="phone" className="error-message" />
                        </div>
                        <div className="form-group normal-group inline-group">
                            <label htmlFor="contactHour" className="label">Melhor horário para falar:</label>
                            <Field name="bestTime" as="select" className="form-control custom-select" id="contactHour">
                                <option value="">Selecione</option>
                                <option value="Manhã">Manhã</option>
                                <option value="Almoço">Almoço</option>
                                <option value="Tarde">Tarde</option>
                                <option value="Noite">Noite</option>
                            </Field>
                            <ErrorMessage component="span" name="bestTime" className="error-message" />
                        </div>
                        <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('acceptPolicy',!values.acceptPolicy) } }>
                            <input type="checkbox" className="custom-control-input" name="acceptPolicy" checked={values.acceptPolicy} />
                            <label className="custom-control-label">Ao fornecer meus dados eu concordo em receber comunicações de acordo com a <RouterLink to="/PolticadePrivacidadeAnage.pdf" target="_blank">Política de Privacidade</RouterLink>.</label>
                        </div>
                        <button type="submit" name="button" className="btn btn-10">Enviar</button>
                    </FormikForm>
                    )}
                </Formik>
            </div>
        </>
    )
}
