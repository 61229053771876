import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ErrorMessage, Formik, Form as FormikForm, Field, Label } from 'formik';
import * as Yup from 'yup';
import { addLoadingGlobal, removeLoadingGlobal, addMessage } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import ClassContact from 'src/models/CalculateModels';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import { Link as RouterLink } from 'react-router-dom';
import millenials from 'src/assets/images/icons/icon-aluguel-fortalecimento.webp';
import trend from 'src/assets/images/icons/icon-aluguel-tendencia.webp';
import lifestyle from 'src/assets/images/icons/icon-aluguel-lifestyle.webp';
import appreciation from 'src/assets/images/icons/icon-aluguel-valorizacao.webp';
import reduce from 'src/assets/images/icons/icon-aluguel-reduza.webp';
import yeld from 'src/assets/images/icons/icon-aluguel-redimento.webp';

import background from 'src/assets/images/calculadora-aluguel-anage.webp';
import img1 from 'src/assets/images/img-rental.jpg';
import return1 from 'src/assets/images/retorno-santo-antonio.webp';
import return2 from 'src/assets/images/retorno-america.webp';
import logo from 'src/assets/images/logo-anage-calculadora.svg';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const useStyles = makeStyles(() => ({
    root: {}
}));

let classContact = new ClassContact();

const defaultFormShape = classContact.getObjects;

function FindProperty({history}) {
    const classes = useStyles();
    const { trackEvent } = useAnalytics();
    const dispatch = useDispatch();
    const contactApi = new ManagerApi('/contact/rentalcalculator');

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        phone: Yup.string().required('Campo obrigatório.'),
        city: Yup.string().required('Campo obrigatório.'),
        neighbourhood: Yup.string().required('Campo obrigatório.'),
        category: Yup.string().required('Campo obrigatório.'),
        privateArea: Yup.string().required('Campo obrigatório.'),
        propertyAge: Yup.string().required('Campo obrigatório.'),
        interest: Yup.string().required('Campo obrigatório.'),
        acceptPolicy: Yup.string().required('Campo obrigatório.'),
    });

    const generalInformation = useSelector((state) => state.interface.generalInformation);
    
    const styleSuccess = {
        color:'green',
        textAlign:'center'
    }

    const styleDeny = {
        color:'red',
        textAlign:'center'
    }

    let pageTitle = 'Calculadora de Aluguel';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="rental-calculator">
                <div className="page-header" style={{ backgroundImage: 'url(' + background + ')' }}>
                    <div className="description">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 offset-md-1">
                                    <h1 className="title title-1">
                                        Calcule agora o retorno do investimento do seu imóvel alugado!
                                    </h1>
                                    <h2 className="title title-7">
                                        Você é proprietário de imóvel? Alugue e faça seu dinheiro render!
                                    </h2>
                                    <p className="text text-1">
                                        Com a calculadora de retorno do investimento da Anagê, você faz a conta de quanto vale o aluguel do seu imóvel e de quanto você pode lucrar com ele.
                                    </p>
                                    <a href="#calcular_retorno" className="btn btn-1">
                                        Calcular Retorno do Investimento    
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="trend">
                        <div className="container">    
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="block-title">
                                        <h3 className="title title-2">
                                            A locação é uma forte TENDÊNCIA para 2020!
                                        </h3>
                                        <p className="text text-1">
                                            Especialistas garantem que haverá um crescimento no setor de aluguel. Entre os principais motivos para essa evolução estão:
                                        </p>
                                    </div>
                                    <div className="topics">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="topic">
                                                    <img alt="" src={millenials} className="icon"></img>
                                                    <h4 className="title title-7">
                                                        O fortalecimento dos millennials
                                                    </h4>
                                                    <p className="text text-1">
                                                        como camada economicamente ativa.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="topic">
                                                    <img alt="" src={trend} className="icon"></img>
                                                    <h4 className="title title-7">
                                                        Tendência global
                                                    </h4>
                                                    <p className="text text-1">
                                                        enquanto nas principais cidades do Brasil apenas 20% dos imóveis são alugados, a média mundial é de 47%.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="topic">
                                                    <img alt="" src={lifestyle} className="icon"></img>
                                                    <h4 className="title title-7">
                                                        Lifestyle
                                                    </h4>
                                                    <p className="text text-1">
                                                        hoje em dia muitas pessoas preferem investir em experiências e não em bens.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="investment-return">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="image" style={{ backgroundImage: 'url(' + img1 + ')' }}></div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="description">
                                                <h3 className="title title-2">
                                                    ALUGUE enquanto espera para VENDER o imóvel
                                                </h3>
                                                <p className="text text-1">
                                                    O aluguel é uma excelente opção para quem também pretende vender o imóvel. Enquanto você espera o melhor momento e o melhor negócio para o seu imóvel, aproveite para lucrar com ele!
                                                </p>
                                                <a href="#calcular_retorno" className="btn btn-1">
                                                    Calcular Retorno do Investimento
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="districts-return">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="baloon-item">
                                        <h3 className="title title-2">
                                            Retorno do investimento nos principais bairros
                                        </h3>
                                        <p className="text text-1">
                                            A Anagê calculou o retorno do investimento dos imóveis nos principais bairros de Joinville:
                                        </p>
                                    </div>
                                    <div className="districts">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <h4 className="title title-6">
                                                        Santo Antônio
                                                    </h4>
                                                    <img alt="" src={return1} className="image"></img>
                                                    <p className="text text-6">
                                                        Imóvel com 2 dormitórios: R$ 315 de custo médio com condomínio e encargos
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <h4 className="title title-6">
                                                        América
                                                    </h4>
                                                    <img alt="" src={return2} className="image"></img>
                                                    <p className="text text-6">
                                                        Imóvel com 2 dormitórios: R$ 315 de custo médio com condomínio e encargos
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="action">
                                        <a href="#calcular_retorno" className="btn btn-6">
                                            Calcular Retorno do Investimento
                                        </a>
                                    </div>
                                    <p className="text text-3">
                                        *Retorno do investimento baseado nas locações realizadas pela Anagê no período de janeiro de 2019 a janeiro de 2020.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="benefits">
                        <div className="container">
                            <div className="block-title">
                                <h3 className="title title-2">
                                    Vantagens de alugar seu imóvel
                                </h3>
                            </div>
                            <div className="topics">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="topic">
                                            <img alt="" src={appreciation} className="icon"></img>
                                            <h4 className="title title-7">
                                                Valorização
                                            </h4>
                                            <p className="text text-1">
                                                Enquanto você espera para vender, o seu imóvel valoriza. A indicação dos especialistas é esperar sempre o aquecimento do mercado imobiliário e da economia para fechar o melhor negócio.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="topic">
                                            <img alt="" src={reduce} className="icon"></img>
                                            <h4 className="title title-7">
                                                Reduza seus custos
                                            </h4>
                                            <p className="text text-1">
                                                Alugar gera economia para o proprietário. Os custos fixos do imóvel como IPTU, condomínio, taxas de luz, água e gás se tornam responsabilidade do inquilino.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="topic">
                                            <img alt="" src={yeld} className="icon"></img>
                                            <h4 className="title title-7">
                                                Rendimento fixo
                                            </h4>
                                            <p className="text text-1">
                                                O valor fixo do aluguel será integrado ao seu planejamento financeiro mensal, contribuindo assim para manter as finanças em dia. Possibilita ainda a espera do melhor momento para vender o imóvel.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="benefits-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <img alt="" src={logo} className="logo"></img>
                                    <div className="block-title">
                                        <h3 className="title title-2">
                                            A ANAGÊ oferece uma série de vantagens. Confira!
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="benefits-list">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <h4 className="title title-6">
                                                        VELOCIDADE
                                                    </h4>
                                                    <p className="text text-1">
                                                        A imobiliária que mais aluga em Joinville.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <h4 className="title title-6">
                                                        GARANTIA
                                                    </h4>
                                                    <p className="text text-1">
                                                        Aluguel e encargos por até 6 meses e vistoria minuciosa. *
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <h4 className="title title-6">
                                                        DIVULGAÇÃO
                                                    </h4>
                                                    <p className="text text-1">
                                                        No site da Anagê e nos principais portais do país com vídeos e fotos profissionais.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <h4 className="title title-6">
                                                        ATENDIMENTO PERSONALIZADO
                                                    </h4>
                                                    <p className="text text-1">
                                                        Pessoalmente nas nossas lojas ou de forma digital.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <h4 className="title title-6">
                                                        AGILIDADE
                                                    </h4>
                                                    <p className="text text-1">
                                                        Negociação e assinatura do contrato online.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <h4 className="title title-6">
                                                        TRADIÇÃO
                                                    </h4>
                                                    <p className="text text-1">
                                                        Mais de 3 décadas no mercado imobiliário.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <h4 className="title title-6">
                                                        SEGURANÇA
                                                    </h4>
                                                    <p className="text text-1">
                                                        Cobrança especializada, incluindo encargos processuais. *
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="item">
                                                    <h4 className="title title-6">
                                                        ENGAJAMENTO
                                                    </h4>
                                                    <p className="text text-1">
                                                        Empresa socialmente engajada em ações filantrópicas.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="calculation" id="calcular_retorno">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="block-title">
                                        <h3 className="title title-2">
                                            Calcule aqui o retorno do investimento do seu imóvel
                                        </h3>
                                    </div>
                                    <div className="steps">
                                        <div className="item">
                                            <div className="step">
                                                1
                                            </div>
                                            <p className="text text-1">
                                                O valor que você pode receber de aluguel.
                                            </p>
                                        </div>
                                        <div className="item">
                                            <div className="step">
                                                2
                                            </div>
                                            <p className="text text-1">
                                                O valor dos custos do imóvel parado.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 offset-md-1">
                                    <div className="block-form">
                                        <Formik
                                            initialValues={defaultFormShape}
                                            enableReinitialize
                                            validationSchema={validationSchema}
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            onSubmit={(values , {resetForm, setStatus}) => {
                                                let data = new FormData();
                                                data = values;

                                                if(!values.acceptPolicy) {
                                                    dispatch(addMessage('Para prosseguir, você precisa aceitar os termos de envio.', 'error'));
                                                    return;
                                                }

                                                dispatch(addLoadingGlobal());
                                                contactApi.post(data).then(response => {
                                                    dispatch(removeLoadingGlobal());
                                                    if (response.data.success == true) {
                                                        dispatch(addMessage('Registro realizado com sucesso.', 'success'));
                                                        resetForm();
                                                        trackEvent('Envio de formulário', 'Calculadora de aluguel', '')
                                                        history.push('/calculadora-de-aluguel/resultado?id='+response.data.content[0]);

                                                    }
                                                    else {
                                                        dispatch(addMessage('Não foi possível enviar o contato, por favor tente novamente mais tarde.', 'error'));
                                                    }
                                                });
                                            }}
                                        >
                                            { ({ handleBlur, handleChange, setFieldValue, status, values }) => (
                                            <FormikForm className="form">
                                                <div className="block-group">
                                                    <h4 className="title title-8">Dados Pessoais</h4>
                                                    <div className="form-group">
                                                        <label htmlFor="calculateFormName" className="label-control">Nome</label>
                                                        <Field type="text" className="form-control" id="calculateFormName" onBlur={handleBlur} onChange={handleChange} name="name" value={values.name} placeholder="Digite seu nome" />
                                                        <ErrorMessage component="span" name="name" className="error-message" />
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="calculateFormEmail" className="label-control">E-mail</label>
                                                                <Field type="email" className="form-control" id="calculateFormEmail" onBlur={handleBlur} onChange={handleChange} name="email" value={values.email} placeholder="exemplo@email.com.br" />
                                                                <ErrorMessage component="span" name="email" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="calculateFormPhone" className="label-control">DDD + Telefone/WhatsApp</label>
                                                                <Field type="text" className="form-control" id="calculateFormPhone" onBlur={handleBlur} onChange={handleChange} name="phone" value={values.phone} placeholder="(00) 00000-00000" />
                                                                <ErrorMessage component="span" name="phone" className="error-message" />
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="block-group">
                                                    <h4 className="title title-8">Dados do imóvel</h4>
                                                    <div className="form-group">
                                                        <label htmlFor="calculateFormCity" className="label-control">Cidade do imóvel</label>
                                                        <Field name="city" as="select" className="form-control custom-select" id="calculateFormCity">
                                                            <option value="">Cidade</option>
                                                            {generalInformation.locations['alugar'].map( (location ,key) => {
                                                                return (
                                                                    <option key={key} value={location.city}>{location.city}</option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <ErrorMessage component="span" name="city" className="error-message" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="calculateFormNeighbourhood" className="label-control">Bairro do imóvel</label>
                                                        <Field name="neighbourhood" as="select" className="form-control custom-select" id="calculateFormNeighbourhood">
                                                            <option value="">Bairro</option>
                                                            {values.city && generalInformation.locations['alugar'].map( (location ,key) => {
                                                                if(location.city == values.city) {
                                                                    return location.neighbourhood.map((neighbourhood, key) => {
                                                                        return (
                                                                            <option key={key} value={neighbourhood}>{neighbourhood}</option>
                                                                        );
                                                                    })
                                                                }
                                                            })}
                                                        </Field>
                                                        <ErrorMessage component="span" name="neighbourhood" className="error-message" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="calculateFormCategory" className="label-control">Tipo do Imóvel</label>
                                                        <Field name="category" as="select" className="form-control custom-select" id="calculateFormCategory">
                                                            <option value="">Selecione o tipo</option>
                                                            {generalInformation.categories['alugar'].map( (category ,key) => {
                                                                return (
                                                                    <option key={key} value={category.name}>{category.name}</option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <ErrorMessage component="span" name="category" className="error-message" />
                                                    </div>
                                                    <div className="form-group normal-group">
                                                        <Field type="text" className="form-control" id="calculateFormArea" onBlur={handleBlur} onChange={handleChange} name="privateArea" placeholder="Área Privativa (em m²)" />
                                                        <ErrorMessage component="span" name="privateArea" className="error-message" />
                                                    </div>
                                                    <div className="block-check">
                                                        <p className="text text-5">
                                                            Idade do Imóvel
                                                        </p>
                                                        <div className="group">
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="radioAge1" name="propertyAge" className="custom-control-input" onChange={handleChange} value="Até 20 anos" />
                                                                <label className="custom-control-label" htmlFor="radioAge1">Até 20 anos</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="radioAge2" name="propertyAge" className="custom-control-input" onChange={handleChange} value="Acima de 20 anos" />
                                                                <label className="custom-control-label" htmlFor="radioAge2">Acima de 20 anos</label>
                                                            </div>
                                                            <ErrorMessage component="span" name="propertyAge" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group normal-group">
                                                        <Field type="text" className="form-control" id="calculateFormIptu" onBlur={handleBlur} onChange={handleChange} name="iptuValue" placeholder="Valor do IPTU mensal (Opcional)" />
                                                    </div>
                                                    <div className="form-group normal-group">
                                                        <Field type="text" className="form-control" id="calculateFormCondominium" onBlur={handleBlur} onChange={handleChange} name="condominiumValue" placeholder="Valor do condomínio mensal (Opcional)" />
                                                    </div>
                                                    <div className="block-check">
                                                        <p className="text text-5">
                                                            Interesse
                                                        </p>
                                                        <div className="group">
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="radioInterest1" name="interest" className="custom-control-input" onChange={handleChange} value="Quero Alugar" />
                                                                <label className="custom-control-label" htmlFor="radioInterest1">Quero Alugar</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="radioInterest2" name="interest" className="custom-control-input" onChange={handleChange} value="Curiosidade" />
                                                                <label className="custom-control-label" htmlFor="radioInterest2">Curiosidade</label>
                                                            </div>
                                                            <ErrorMessage component="span" name="interest" className="error-message" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('acceptPolicy',!values.acceptPolicy) } }>
                                                    <input type="checkbox" className="custom-control-input" name="acceptPolicy" checked={values.acceptPolicy} />
                                                    <label className="custom-control-label">Ao preencher este formulário concordo com a coleta e tratamento dos meus dados, conforme <RouterLink to="/PolticadePrivacidadeAnage.pdf" target="_blank">Política de Privacidade</RouterLink>, nos termos da Lei 13.709/2018, permitindo desde já eventual armazenamento destes dados e o contato comercial da Anagê Imóveis</label>
                                                </div>
                                                {status && status.msg && (
                                                    <p style={status.sent == true ? styleSuccess : styleDeny}>
                                                        {status.msg}
                                                    </p>
                                                )}
                                                <button type="submit" name="button" className="btn btn-1">Calcular</button>
                                            </FormikForm>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <Newsletter />
        </Page>
    );
}

export default FindProperty;
