import React, { useState, useEffect } from 'react';
import CardMainProperty from 'src/components/AnageImoveis/CardMainProperty';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ManagerApi from 'src/services/managerApi';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function MainProperties() {
    const realEstateApi = new ManagerApi('/realestate');

    const [realEstatesBuy, setRealEstatesBuy] = useState(null);
  
    const fetchRealEstate = (filter, callbackSet) => {
        realEstateApi.getList({
            ...filter,
            paginator: {
                pageItemsPerPage: 20,
                sortOrder: {
                  createdAt: 'DESC'
                }
            }
        }).then((response) => {

          if (response.data.success) {
            let list = [];
            let cont = 0;
            while(cont<5) {
              let randomIndex = Math.floor(Math.random() * response.data.content.length);
              list.push(response.data.content.splice(randomIndex,1)[0]);
              cont++;
            }
            callbackSet(list);
          }
        }).catch((error) => {
        }).then(() => {
        });
    }

    useEffect(() => {
        fetchRealEstate({
          status: 'comprar',
          featured: 1,
          tagSelect: 1,
          tagSeloSelect: 1
        } ,setRealEstatesBuy);
    }, []);

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
    };

    return (
        <div className="main-section">
            {realEstatesBuy && (
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    pagination={pagination}
                    modules={[Pagination]}
                >
                    {realEstatesBuy.map( (realEstate) => (
                        <SwiperSlide>
                            <CardMainProperty realEstate={realEstate} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>
    )
}
