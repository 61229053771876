import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import img1 from 'src/assets/images/inquilino-manutencao/maintenance-01.png';
import img2 from 'src/assets/images/inquilino-manutencao/maintenance-02.png';
import img3 from 'src/assets/images/inquilino-manutencao/maintenance-03.png';
import img4 from 'src/assets/images/inquilino-manutencao/maintenance-04.png';
import img5 from 'src/assets/images/inquilino-manutencao/maintenance-05.png';
import img6 from 'src/assets/images/inquilino-manutencao/maintenance-06.png';
import img7 from 'src/assets/images/inquilino-manutencao/maintenance-07.png';
import img8 from 'src/assets/images/inquilino-manutencao/maintenance-08.png';
import img9 from 'src/assets/images/inquilino-manutencao/maintenance-09.png';

const useStyles = makeStyles(() => ({
    root: {}
}));

function TenantAreaMaintenance() {
    const classes = useStyles();

    let pageTitle = 'Sou Inquilino';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            to: '/area-do-cliente',
            title: 'Área do Cliente'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="client-content tenant-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-title">
                                <h2 className="title title-10">
                                  Quem é responsável pela manutenção<br/> do imóvel alugado
                                </h2>

                                <p>
                                  O imóvel apresentou problemas, precisa de reparos ou consertos? Confira quem é o responsável em cada caso
                                </p>
                                
                                <a href="https://admin.refera.com.br/solicitar-manutencao/anage" className="btn btn-1" target="_blank">
                                    Abrir Chamado
                                </a>

                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="box simple">
                                <div className="info-centered">
                                    <h3 className="title title-11">
                                        Responsabilidade do proprietário
                                    </h3>
                                    <div className="row" style={{textAlign: 'center'}}>
                                      <div className="col-md-3">
                                        <img alt="" src={img1} className="image img-fluid"></img>
                                        <p>Reparos de problemas estruturais</p>
                                      </div>
                                      <div className="col-md-3">
                                        <img alt="" src={img2} className="image img-fluid"></img>
                                        <p>Consertos de infiltrações ou vazamentos ocultos</p>
                                      </div>
                                      <div className="col-md-3">
                                        <img alt="" src={img3} className="image img-fluid"></img>
                                        <p>Troca de caixa de luz, fiação e disjuntores estragados</p>
                                      </div>
                                      <div className="col-md-3">
                                        <img alt="" src={img4} className="image img-fluid"></img>
                                        <p>Troca de caixa d’água e consertos no telhado</p>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="box simple">
                              <div className="info-centered">
                                <h3 className="title title-11">
                                  Responsabilidade do inquilino
                                </h3>
                                <div className="row" style={{textAlign: 'center'}}>
                                  <div className="col-md-3">
                                    <img alt="" src={img5} className="image img-fluid"></img>
                                    <p>Troca de torneiras, tomadas, fechaduras, lâmpadas e sifões</p>
                                  </div>
                                  <div className="col-md-3">
                                    <img alt="" src={img6} className="image img-fluid"></img>
                                    <p>Manutenção e revisão de ar-condicionado e aquecedor à gás</p>
                                  </div>
                                  <div className="col-md-3">
                                    <img alt="" src={img7} className="image img-fluid"></img>
                                    <p>Troca de resistência do chuveiro</p>
                                  </div>
                                  <div className="col-md-3">
                                    <img alt="" src={img8} className="image img-fluid"></img>
                                    <p>Troca ou conserto de interfone e campainha</p>
                                  </div>
                                  <div className="col-md-3">
                                    <img alt="" src={img9} className="image img-fluid"></img>
                                    <p>Limpeza e desentupimento de vasos sanitários, pias, caixa de gordura e ralos</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="block-title">
                                <h2 className="title title-10">
                                  Deseja abrir um chamado?
                                </h2>
                                <a href="https://admin.refera.com.br/solicitar-manutencao/anage" className="btn btn-1" target="_blank">
                                    Abrir Chamado
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default TenantAreaMaintenance;
