import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { ErrorMessage, Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import slugify from 'slugify';
import { setRealEstateFilters } from 'src/actions';

import searchIcon from 'src/assets/images/icons/feather-search.svg';

const validationSchema = Yup.object().shape({
  // name: Yup.string().required('Campo obrigatório.'),
  // email: Yup.string().required('Campo obrigatório.'),
  // phone: Yup.string().required('Campo obrigatório.'),
  // department: Yup.string().required('Campo obrigatório.'),
  // message: Yup.string().required('Campo obrigatório.'),
  // bestTime: Yup.string().required('Campo obrigatório.'),
});

export default function SearchFilters({ onFilter, compact = false, showMapButton = false, showMap, onChangeMapVisibility }) {

  const generalInformation = useSelector((state) => state.interface.generalInformation);
  const realEstateFilters = useSelector((state) => state.interface.realEstateFilters);
  const suggestion = false;
  
  const [statusLabel, setStatusLabel] = useState(realEstateFilters.launch ? 'lancamentos' : realEstateFilters.status);
  
  const [filterOpen, setFilterOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(false);
  
  const [advancedMode, setAdvancedMode] = useState(false);
  const [checked, setChecked] = useState(false);
  
  const dispatch = useDispatch();
  const history = useHistory();

  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let defaultFormShape = {
    status: "comprar",
    city: '',
    neighbourhood: [],
    category: '',
    subcategory: [],
    minBedrooms: '',
    maxBedrooms: '',
    parkingSpaces: '1',
    toilets: '1',
    minPrice: '',
    maxPrice: '',
    minArea: '',
    maxArea: '',
    building: '',
    code: '',
    furnished: 0,
    semiFurnished: 0,
    elevator: 0,
    video: 0,
    tagSelect: 1,
    // ...realEstateFilters
  };

  const [modalLocation, setModalLocation] = useState({
    open: false,
    city: defaultFormShape.city,
    neighbourhoods: [...defaultFormShape.neighbourhood]
  });

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleFilterOpen = (type) => {
    if (filterOpen == type) {
      setFilterOpen('');
    }
    else {
      setFilterOpen(type)
    }
  }

  const handleClickAwayFilterOpen = (type) => {
    if (filterOpen != '' && filterOpen == type) {
      setFilterOpen('');
    }
  }

  function handleToggleMap() {
    onChangeMapVisibility(!showMap);
  }

  const handleFilters = (values) => {

    let statusLabel = '';
    if (values.status == 'alugar') {
      statusLabel = 'alugar';
    }
    else {
      statusLabel = 'comprar';
      if (values.launch) {
        statusLabel = 'lancamentos';
      }
    }

    let url = [];
    let params = [];
    let validateParent = false;
    url.push(statusLabel);
    if(values.category) {
      generalInformation.categories[values.status.toLowerCase()].map(category => {
          if(category.id == values.category) {
              url.push(category.slug);
          }
      })
    }
    if (values.city) {
      url.push(slugify(values.city.toLowerCase()));
    }
    if (values.neighbourhood) {
      let neighbourhoods = [...values.neighbourhood];
      neighbourhoods.sort();
      neighbourhoods.map((element, index) => {
        element = element.toLowerCase().replaceAll(' ', '');
        element = slugify(element);
        neighbourhoods[index] = element;
      });
      if(neighbourhoods.length) {
        url.push(neighbourhoods.join('-'));
      }
    }
    if (values.code) {
      params = '?code=' + values.code
    }
    else if (values.building) {
      params = '?building=' + encodeURI(values.building)
    }

    if(!suggestion) {
      history.push('/' + url.join('/') + params);
    }

    dispatch(setRealEstateFilters({ ...values }));

    onFilter();
  }

  return (
    <Formik
      initialValues={defaultFormShape}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { resetForm, setStatus, setFieldValue }) => {

        values.furnished = values.furnished.length ? 1 : 0;
        values.semiFurnished = values.semiFurnished.length ? 1 : 0;
        values.video = values.video.length ? 1 : 0;
        values.elevator = values.elevator.length ? 1 : 0;

        if (currentCategory != values.category) {
          delete values.subcategory;
        }

        setCurrentCategory(values.category);

        handleFilterOpen('');

        let newFilters = { ...values };
        if (statusLabel == 'lancamentos') {
          newFilters.status = 'comprar';
          newFilters.launch = 1;
        }
        else {
          newFilters.status = statusLabel;
          if (newFilters.launch) {
            delete newFilters.launch;
          }
        }

        if (newFilters.minPrice) {
          newFilters.minPrice = parseFloat(newFilters.minPrice.toString().replace(/\D/g, ""))
        }
        if (newFilters.maxPrice) {
          newFilters.maxPrice = parseFloat(newFilters.maxPrice.toString().replace(/\D/g, ""))
        }

        handleFilters(newFilters);

        //Reseta filtros únicos
        setFieldValue('code', '');
      }}
    >
      {({ values, setFieldValue, handleBlur, handleChange }) => {

        const formatCurrency = (value) => {
          if (value) {
            value = parseFloat(value.toString().replace(/\D/g, "")).toLocaleString('pt-BR', { minimumFractionDigits: 0 });;
          }
          return value;
        }

        const handleChangeCurrency = (e) => {
          setFieldValue(e.target.name, formatCurrency(e.target.value));
        }

        return (
          <div className="filters-section">
            <div className="container">
              <div className="block-filters position-static">
                <FormikForm className="form">
                  <div className="form-row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="searchForm-category" className="label">Tipo do Imóvel</label>
                        <select className="form-control custom-select" id="searchForm-category" name="category" onBlur={handleBlur} onChange={handleChange} value={values.category}>
                          <option value="">Selecione o tipo</option>
                          {generalInformation.categories[statusLabel].map((category, key) => {
                            if ((statusLabel == 'comprar' || statusLabel == 'lancamentos') && category.parent_id) {
                              return <></>;
                            }
                            return (<option key={key} value={category.id}>{category.name}</option>);
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label">Localização</label>
                        <div className={values.city ? "block-control active" : "block-control"}>
                          <div className="block-toggle" onClick={() => { setModalLocation({ ...modalLocation, open: true }) }}>
                            {!values.city ? (
                              <span className="text text-1">Cidade / Bairro</span>
                            ) : (
                              <div>
                                {values.city && (
                                  <span className="text text-2">{values.city}</span>
                                )}
                                {values.neighbourhood.length > 0 && (
                                  <span className="text text-2"> - {values.neighbourhood.length} {values.neighbourhood.length > 1 ? 'bairros' : 'bairro'}</span>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="label">Quartos</label>
                        <div className="form-row">
                          <div className="col-6">
                            <select className="form-control custom-select" name="minBedrooms" onBlur={handleBlur} onChange={handleChange} value={values.minBedrooms}>
                              <option value="">Selecione</option>
                              {[1, 2, 3, 4, 5].map((quantity, key) => {
                                return (<option key={key} value={quantity}>{quantity}</option>);
                              })}
                            </select>
                          </div>
                          <div className="col-6">
                            <select className="form-control custom-select" name="maxBedrooms" onBlur={handleBlur} onChange={handleChange} value={values.maxBedrooms}>
                              <option value="">Selecione</option>
                              {[1, 2, 3, 4, 5].map((quantity, key) => {
                                return (<option key={key} value={quantity}>{quantity}</option>);
                              })}
                            </select>
                          </div>
                        </div>
                        <input type="hidden" name="faixapreco" className="range-filter-input-final" value="{{ realEstateFilters['faixapreco'] is defined ? realEstateFilters['faixapreco'] }}" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <div className="block-items">
                          <label className="label">Vagas Garagem</label>
                          <div className="actions">
                            {['1', '2', '3', '4'].map((item, key) => {
                              let label = item + '+';
                              let className = "btn btn-small";
                              if (item === values.parkingSpaces)
                                className = className + ' active';

                              return (
                                <button key={key} type="button" className={className} onClick={() => { setFieldValue('parkingSpaces', item) }}>
                                  {label}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="searchForm-category" className="label">Status da obra</label>
                        <select className="form-control custom-select" id="searchForm-constructionStatus" name="constructionStatus" onBlur={handleBlur} onChange={handleChange} value={values.constructionStatus}>
                          <option value="">Selecione o status</option>
                          {/* {generalInformation.categories[statusLabel].map((category, key) => {
                            if ((statusLabel == 'comprar' || statusLabel == 'lancamentos') && category.parent_id) {
                              return <></>;
                            }
                            return (<option key={key} value={category.id}>{category.name}</option>);
                          })} */}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="searchForm-minArea" className="label">Área Privativa (m²)</label>
                        <div className="form-row">
                          <div className="col-6">
                            <input type="text" name="minArea" className="form-control small range-filter-input-min" placeholder="min" value={values.minArea} onBlur={handleBlur} onChange={handleChange} />
                          </div>
                          <div className="col-6">
                            <input type="text" name="maxArea" className="form-control small range-filter-input-max" placeholder="max" value={values.maxArea} onBlur={handleBlur} onChange={handleChange} />
                          </div>
                        </div>
                        <input type="hidden" name="faixapreco" className="range-filter-input-final" value="{{ filters['faixapreco'] is defined ? filters['faixapreco'] }}" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="searchForm-minArea" className="label">Faixa de Preço (R$)</label>
                        <div className="form-row">
                          <div className="col-6">
                            <input type="text" name="minPrice" className="form-control small range-filter-input-min input-currency" placeholder="min" value={formatCurrency(values.minPrice)} onBlur={handleBlur} onChange={handleChangeCurrency} />
                          </div>
                          <div className="col-6">
                            <input type="text" name="maxPrice" className="form-control small range-filter-input-max input-currency" placeholder="max" value={formatCurrency(values.maxPrice)} onBlur={handleBlur} onChange={handleChangeCurrency} />
                          </div>
                        </div>
                        <input type="hidden" name="faixapreco" className="range-filter-input-final" value="{{ filters['faixapreco'] is defined ? filters['faixapreco'] }}" />
                      </div>
                    </div>
                    <div className="col-12 show-mobile search-properties">
                      <div className="form-group normal-group">
                          <input type="text" 
                              className="form-control" 
                              placeholder="Código ou nome do empreendimento"
                              name="code" value={values.code} onBlur={handleBlur} onChange={handleChange} 
                              />
                          <button type="submit" className="btn btn-ok">ok</button>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="block-submit">
                          <button type="submit" className="btn btn-10">
                            <img src={searchIcon} className="icon" />  
                            Buscar
                          </button>
                        </div>
                    </div>
                  </div>
                  <Dialog fullWidth={true} maxWidth="lg" onClose={() => { setModalLocation({ ...modalLocation, open: false, neighbourhoods: [] }) }} open={modalLocation.open} className="location-modal">
                      <DialogTitle>
                        <h2 className="title title-3">
                          Selecione a cidade e os bairros de interesse
                        </h2>
                      </DialogTitle>
                      <DialogContent>
                        <div className="row location-options">

                          <div className="col-md-3">
                            {generalInformation.locations[statusLabel].map( (location ,key) => {
                              let items = []
                              items.push(
                                <div key={key} className="col-md-12">
                                  <span className={modalLocation.city && modalLocation.city == location.city ? 'option active' : 'option'} onClick={() => { setModalLocation({ ...modalLocation, city: location.city, neighbourhoods: [] }); }}>
                                    <span>{location.city}</span>
                                  </span>
                                </div>
                              );
                              return items;
                            })}
                          </div>
                          <div className="col-md-9">
                            <div className="location-scrollbar">
                              <div className="row">
                                {generalInformation.locations[statusLabel].map( (location, key) => {
                                  let items = []
                                  if (modalLocation.city && location.city == modalLocation.city) {
                                    location.neighbourhood.map(neighbourhood => {
                                      let checked = modalLocation.neighbourhoods.indexOf(neighbourhood) >= 0;
                                      let handleClick = (neighbourhood) => {
                                        let newModalLocation = { ...modalLocation };
                                        if (checked) {
                                          newModalLocation.neighbourhoods.splice(modalLocation.neighbourhoods.indexOf(neighbourhood), 1);
                                        }
                                        else {
                                          newModalLocation.neighbourhoods.push(neighbourhood);
                                        }
                                        setModalLocation(newModalLocation);
                                      }
                                      items.push(
                                        <div key={key} className="col-md-3">
                                          <div className="custom-control custom-checkbox small-checkbox" onClick={() => handleClick(neighbourhood)}>
                                            <input type="checkbox" className="custom-control-input" checked={checked} />
                                            <label className="custom-control-label">{neighbourhood}</label>
                                          </div>
                                        </div>
                                      );
                                    });
                                  }
                                  return items;
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <div className="actions">
                          <button className="btn btn-1" onClick={() => { setModalLocation({ ...modalLocation, open: false }); setFieldValue('neighbourhood', modalLocation.neighbourhoods); setFieldValue('city', modalLocation.city); }}>Confirmar</button>
                        </div>
                      </DialogActions>
                    </Dialog>
                </FormikForm>
              </div>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}
