import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import car from 'src/assets/images/icons/car-white.svg';
import area from 'src/assets/images/icons/expand-square.svg';
import bed from 'src/assets/images/icons/bed-white.svg';

import carBlack from 'src/assets/images/icons/car-black.svg';
import areaBlack from 'src/assets/images/icons/expand-square-black.svg';
import bedBlack from 'src/assets/images/icons/bed-black.svg';

export default function CardProperty({realEstate}) {

    if(!realEstate) {
        return null;
    }
    
    let images = realEstate.gallery;

    let mainImage = ''
    if(images.length) {
      mainImage = images[0].image;
    }

    let mobileSize = window.innerWidth < 768;

    return (
        <>
            <RouterLink to={`/${realEstate.slug}`} target="_blank" className="card card-main-property">
                <div className="property-content" style={{ backgroundImage: 'url(' + mainImage + ')' }}>
                    <div className="description">
                        <div className="info top-info">
                            <RouterLink to={`/${realEstate.slug}`} target="_blank">
                                <h2 className="block-title">
                                    <span className="title-small">{realEstate.category && realEstate.category.name}</span>
                                    <span className="title title-10">{realEstate.neighbourhood} - {realEstate.city}</span>
                                </h2>
                            </RouterLink>
                        </div>
                        <div className="info bottom-info">
                            <div className="feature">
                                {realEstate.bedrooms !== '0' && (
                                    <>
                                        <img alt="" src={!mobileSize ? bed : bedBlack} className="icon"></img>
                                        <span className="info-feature">{realEstate.bedrooms} quartos</span>
                                    </>
                                )}
                            </div>
                            <div className="feature">
                                <img alt="" src={!mobileSize ? area : areaBlack} className="icon"></img>
                                <span className="info-feature">{Number.parseFloat(realEstate.area).toFixed(0)}m²</span>
                            </div>
                            <div className="feature">
                                {realEstate.parkingSpaces !== '0' && (
                                    <>
                                        <img alt="" src={!mobileSize ? car : carBlack} className="icon"></img>
                                        <span className="info-feature">{realEstate.parkingSpaces} vagas</span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </RouterLink>
        </>
    );
}
