import React, { useState, useEffect } from 'react';
import axios from 'src/utils/apiWP';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import Page from 'src/components/Page';
import Posts from 'src/views/pages/Blog/List/Posts';

function ShowCase({ match, history, location }) {

    const dispatch = useDispatch();
    const [currentCategory, setCurrentCategory] = useState(false);
    const [posts, setPosts] = useState([]);
    const [filters, setFilters] = useState('');
    const [paginator, setPaginator] = useState({
        currentPage: 0,
        perPage: 10,
        totalPages: 0,
        totalRecords: 0,
    });
    const [categories, setCategories] = useState([]);

    let currentCategoryType = '';
    let currentCategorySlug = '';
    if(match.params.categorySlug) {
        currentCategoryType = 'categories';
        currentCategorySlug = match.params.categorySlug;
    }
    else if(match.params.tagSlug) {
        currentCategoryType = 'tags';
        currentCategorySlug = match.params.tagSlug;
    }

    const fetchPosts = async () => {

        if(paginator.currentPage == 0 || currentCategorySlug && !currentCategory) {
            return;
        }

        let requestId = uuidv4();

        dispatch(addLoadingGlobal(requestId));
        
        let slugFilter = '';
        if(currentCategory) {
            slugFilter = '&' + currentCategoryType + '=' + currentCategory.id
        }

        axios.get(`https://blog.anageimoveis.com.br/wp-json/wp/v2/posts?_embed&page=${paginator.currentPage}&per_page=5${slugFilter}&search=${filters}`).then((response) => {
            if (response.request.status === 200) {
                let posts = response.data;
                setPaginator({
                    ...paginator,
                    totalPages: parseInt(response.headers['x-wp-totalpages']),
                    totalRecords: parseInt(response.headers['x-wp-total']),
                });
                setPosts(posts);
            }
        }).catch((error) => {
            console.log(error);
        }).then(() => {
            dispatch(removeLoadingGlobal(requestId));
        });
    };

    const fetchCategories = async () => {
        let requestId = uuidv4();

        dispatch(addLoadingGlobal(requestId));
        axios.get('https://blog.anageimoveis.com.br/wp-json/wp/v2/categories?hide_empty=true&per_page=100').then((response) => {
            if (response.request.status === 200) {
                setCategories(response.data);
            }
        }).catch((error) => {
            console.log(error);
        }).then(() => {
            dispatch(removeLoadingGlobal(requestId));
        });
    };

    const handleFilterChange = (entities) => {
        
        history.push('/blog?busca='+entities);

        // setFilters(entities);
        // setPaginator({
        //     ...paginator,
        //     currentPage: 1,
        //     perPage: 10,
        //     totalPages: paginator.totalPages,
        //     totalRecords: paginator.total
        // });
    };

    const setPages = (indexPage) => {
        setPaginator({
            ...paginator,
            currentPage: indexPage,
            perPage: 10,
            totalPages: paginator.totalPages,
            totalRecords: paginator.total
        });
    };

    
    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams.get('busca')) {
            setFilters(queryParams.get('busca'));
        }
        else {
            setFilters('');
        }

        setPaginator({
            ...paginator,
            currentPage: Number.parseInt(match.params.page ? match.params.page : 1),
        });
    }, [history.location, location]);
    
    useEffect(() => {
        fetchCategories();
        fetchPosts();
    }, []);

    useEffect(() => {
        if(!currentCategorySlug) {
            setCurrentCategory(false);
        }
        else {

            if(currentCategoryType == 'tags') {
                let requestId = uuidv4();
                dispatch(addLoadingGlobal(requestId));
                axios.get('https://blog.anageimoveis.com.br/wp-json/wp/v2/tags?hide_empty=true&per_page=100&slug='+currentCategorySlug).then((response) => {
                    if (response.request.status === 200) {
                        response.data[0].name = "Tag: " + response.data[0].name
                        setCurrentCategory(response.data[0]);
                    }
                }).catch((error) => {
                }).then(() => {
                    dispatch(removeLoadingGlobal(requestId));
                });
            }
            else {
                categories.map( (category) => {
                    if(category.slug === currentCategorySlug) {
                        setCurrentCategory(category);
                    }
                })
            }

        }
    }, [categories, currentCategorySlug]);

    useEffect(() => {
        fetchPosts();
    }, [currentCategory, paginator.currentPage, filters]);

    return (
        <Page
            title={currentCategory ? currentCategory.name : 'Blog Anagê'}
        >
            <Posts
                posts={posts}
                categories={categories}
                onFilter={handleFilterChange}
                paginator={paginator}
                setPages={setPages}
                pageNeighbours={1}
                filters={filters}
                currentCategory={currentCategory}
                currentCategoryType={currentCategoryType}
            />
        </Page>
    );
}

export default ShowCase;
