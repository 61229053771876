import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Slider from './Slider';
import Shops from './Shops';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import image1 from 'src/assets/images/anage-imoveis-imobiliaria-joinville.webp';
import comprarImovelAnage from 'src/assets/images/icons/comprar-imovel-anage.svg';
import anunciarImovelAnage from 'src/assets/images/icons/anunciar-imovel-anage.svg';
import alugarImovelAnage from 'src/assets/images/icons/alugar-imovel-anage.svg';
import reloca from 'src/assets/images/reloca.webp';
import abmi from 'src/assets/images/abmi.webp';

const useStyles = makeStyles(() => ({
    root: {},
    bold: {
      fontWeight: 'bold'
    }
}));

function AboutPage() {
    const classes = useStyles();
    const generalInformation = useSelector((state) => state.interface.generalInformation);

    let pageTitle = 'Sobre a Anagê Imóveis';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 offset-md-1">
                            <div className="info">
                                <h1 className="title title-1">
                                    Somos a Anagê Imóveis - a sua imobiliária em Joinville
                                </h1>
                                <p className="text text-1">
                                    Fundada em 1988, somos hoje a maior imobiliária de Joinville. Com tradição, credibilidade e inovação, oferecemos a mais ampla carteira de imóveis para quem deseja comprar ou alugar.
                                </p>
                                <p className="text text-1">
                                    Somos uma empresa humana, com canais de atendimento digital, possibilitando ao cliente escolher o melhor caminho para se relacionar. Conquistamos o selo Great Place to Work (GPTW), que nos coloca entre as melhores empresas para se trabalhar, e o ISO 9001, que atesta nosso modelo de gestão de qualidade.
                                </p>
                                <p className="text text-1">
                                    Fazemos parte das redes estadual e nacional do setor imobiliário, integrando a Reloca e a ABMI. Tudo para garantir a você a melhor experiência.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1">
                            <img alt="" src={image1} className="img-fluid"></img>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-11  offset-md-1">
                            <div className="info">
                                <h2 className="title title-2">
                                    Missão
                                </h2>
                                <p className="text text-1">
                                  Ser a principal referência em serviços imobiliários de Santa Catarina em 2023.
                                </p>
                            </div>
                            <div className="info">
                                <h2 className="title title-2">
                                    Visão
                                </h2>
                                <p className="text text-1">
                                  <strong className={classes.bold}>Honestidade:</strong> Ser correto e honrar compromissos, com transparência.<br/>
                                  <strong className={classes.bold}>Profissionalismo:</strong> Prestar um serviço com qualidade, segurança e agilidade.<br/>
                                  <strong className={classes.bold}>Simplicidade:</strong> Facilitar a vida das pessoas. Ser simples, claro e objetivo.<br/>
                                  <strong className={classes.bold}>Encantamento:</strong> Atender bem, valorizar as pessoas, ser cortês, dar atenção, resolver os problemas e surpreender positivamente.<br/>
                                  <strong className={classes.bold}>Aperfeiçoamento contínuo:</strong> Constantemente melhorar serviços e processos, capacitar profissionais, buscar as melhores soluções e inovar.
                                </p>
                            </div>
                            <div className="info">
                                <h2 className="title title-2">
                                  Política de Qualidade
                                </h2>
                                <p className="text text-1">
                                  Prestar serviço de locação e venda de imóveis, atendendo as expectativas dos clientes e os requisitos legais, buscando a melhoria contínua através da padronização dos processos e do desenvolvimento dos colaboradores.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="differentials">
                    <Slider />
                </div>
                <div className="video-block">
                    <div className="container">
                        <iframe
                            // key={idx}
                            // src={video.url}
                            src="https://www.youtube.com/embed/E3A1uDF_YJc"
                            frameBorder="0"
                            className="player-item"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            style={{
                                width: "560",
                                height: "315"
                            }}
                        />
                    </div>
                </div>
                <div className="our-services">
                    <div className="container">
                        <div className="block">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="block-title">
                                        <h3 className="title title-2">
                                            Nossos Serviços
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="info">
                                        <img alt="" src={comprarImovelAnage} className="icon"></img>
                                        <h4 className="title title-3">Comprar</h4>
                                        <p className="text text-1">
                                            Com experiência, inovação e compromisso, garantimos o melhor negócio para você que procura um imóvel para comprar em Joinville e região.
                                        </p>
                                        <RouterLink to="/comprar" className="btn btn-2">Quero Comprar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info">
                                        <img alt="" src={alugarImovelAnage} className="icon"></img>
                                        <h4 className="title title-3">Alugar</h4>
                                        <p className="text text-1">
                                            Temos a maior variedade de imóveis para locação em Joinville e região e profissionais especializados para te atender.
                                        </p>
                                        <RouterLink to="/alugar" className="btn btn-2">Quero Alugar</RouterLink>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="info">
                                        <img alt="" src={anunciarImovelAnage} className="icon"></img>
                                        <h4 className="title title-3">Anunciar Imóvel</h4>
                                        <p className="text text-1">
                                            Anuncie seu imóvel na Anagê Imóveis para vender ou alugar de maneira rápida e segura.
                                        </p>
                                        <RouterLink to="/anuncie-seu-imovel" className="btn btn-2">Anunciar Imóvel</RouterLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Shops shops={generalInformation.stores} />
                <div className="partners">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="block-title">
                                    <h3 className="title title-2">
                                        Parceiros Anagê
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5 offset-md-1">
                                <div className="item">
                                    <img alt="" src={reloca} className="img-fluid image"></img>
                                    <h4 className="title title-3">Reloca - Rede de Locações Catarinense</h4>
                                    <p className="text text-1">
                                        A Anagê Imóveis é parceira da Reloca, rede formada pelas maiores imobiliárias de 26 cidades de Santa Catarina. A Reloca oferece soluções rápidas e seguras para a administração, locação e venda de imóveis. A parceria abrange intercâmbio de informações, serviços imobiliários e transferência de tecnologia, o que garante ampliação da capacidade de negócios, treinamento e capacitação dos colaboradores. Dessa forma, temos mais acesso à inovação, apoio e colaboração de entidades do mercado imobiliário.
                                    </p>
                                    <a href="https://www.reloca.com.br/" target="_blank" className="title title-4">
                                        www.reloca.com.br
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-5 offset-md-1">
                                <div className="item">
                                    <img alt="" src={abmi} className="img-fluid image"></img>
                                    <h4 className="title title-3">ABMI - Associação Brasileira do Mercado Imobiliário</h4>
                                    <p className="text text-1">
                                        A ABMI é uma organização formada pelas melhores imobiliárias do país. Com atuação em todo o Brasil, tem o objetivo de potencializar a competitividade das empresas do mercado imobiliário. A Anagê Imóveis é a segunda imobiliária de Santa Catarina a se filiar à entidade.
                                    </p>
                                    <a href="http://abmi.org.br/" target="_blank" className="title title-4">
                                        www.abmi.org.br
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default AboutPage;
