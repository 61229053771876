import * as actionTypes from 'src/actions';

const initialState = {
    globalMessage: {
        open: false,
        message: '',
        severity: ''
    },
    globalLoading: {
        open: false,
        queue: []
    },
    generalInformation: [],
    favorites: [],
    brokerInfo: null,
    realEstateFilters: {
      status: 'comprar',
      tagSelect: 1
    }
}

const interfaceReducer = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {

        case actionTypes.ADD_MESSAGE: {
            newState = {
                ...state,
                globalMessage: {
                    open: true,
                    message: action.message,
                    severity: action.severity
                }
            };
            break;
        }

        case actionTypes.REMOVE_MESSAGE: {
            newState = {
                ...state,
                globalMessage: initialState.globalMessage
            };
            break;
        }

        case actionTypes.ADD_LOADING_GLOBAL: {
            let newQueue = newState.globalLoading.queue;
            newQueue.push(action.id);
            newState = {
                ...state,
                globalLoading: {
                    open: (newQueue.length ? true : false),
                    queue: newQueue
                }
            };
            break;
        }

        case actionTypes.REMOVE_LOADING_GLOBAL: {
            let newQueue = newState.globalLoading.queue;
            for (let i = 0; i < newQueue.length; i++) {
                if (newQueue[i] === action.id) {
                    newQueue.splice(i, 1);
                }
            }
            newState = {
                ...state,
                globalLoading: {
                    open: (newQueue.length ? true : false),
                    queue: newQueue
                }
            };
            break;
        }

        case actionTypes.ADD_INFORMATION: {
            newState = {
                ...state,
                generalInformation: action.information
            };
            break;
        }

        case actionTypes.ADD_FAVORITE: {
            let newList = newState.favorites;
            newList.push(action.favorite);
            newState = {
                ...state,
                favorites: newList
            };
            localStorage.setItem('favoriteList',newList);
            break;
        }

        case actionTypes.REMOVE_FAVORITE: {
            let newList = newState.favorites;
            for (let i = 0; i < newList.length; i++) {
                if (newList[i] === action.favorite) {
                    newList.splice(i, 1);
                }
            }
            newState = {
                ...state,
                favorites: newList
            };
            localStorage.setItem('favoriteList',newList);
            break;
        }

        case actionTypes.ADD_BROKER_INFORMATION: {
            newState = {
                ...state,
                brokerInformation: action.brokerInformation
            };
            if(!action.brokerInformation) {
                localStorage.removeItem('brokerInformation');
            }
            else {
                localStorage.setItem('brokerInformation', JSON.stringify(action.brokerInformation));
            }
            break;
        }

        case actionTypes.SET_REALESTATE_FILTERS: {

            console.log("aqui");

            newState = {
                ...state,
                realEstateFilters: {...action.filters}
            };
            // if(!action.brokerInformation) {
            //     localStorage.removeItem('brokerInformation');
            // }
            // else {
            //     localStorage.setItem('brokerInformation', JSON.stringify(action.brokerInformation));
            // }
            break;
        }

        default:
            return initialState;
    }

    return newState;
};

export default interfaceReducer;
