export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const ADD_LOADING = 'ADD_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';

export const ADD_LOADING_GLOBAL = 'ADD_LOADING_GLOBAL';
export const REMOVE_LOADING_GLOBAL = 'REMOVE_LOADING_GLOBAL';

export const ADD_INFORMATION = 'ADD_INFORMATION';

export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';

export const ADD_BROKER_INFORMATION = 'ADD_BROKER_INFORMATION';

export const SET_REALESTATE_FILTERS = 'SET_REALESTATE_FILTERS';

export const addMessage = (message, severity) => (dispatch) => dispatch({
	type: ADD_MESSAGE,
	message: message,
	severity: severity
});

export const removeMessage = () => (dispatch) => dispatch({
	type: REMOVE_MESSAGE,
});

export const addLoading = (id) => (dispatch) => dispatch({
	type: ADD_LOADING,
	id: id
});

export const removeLoading = (id) => (dispatch) => dispatch({
	type: REMOVE_LOADING,
	id: id
});

export const addLoadingGlobal = (id) => (dispatch) => dispatch({
	type: ADD_LOADING_GLOBAL,
	id: id
});

export const removeLoadingGlobal = (id) => (dispatch) => dispatch({
	type: REMOVE_LOADING_GLOBAL,
	id: id
});

export const addInformation = (information) => (dispatch) => dispatch({
	type: ADD_INFORMATION,
	information: information
});

export const addFavorite = (favorite) => (dispatch) => dispatch({
	type: ADD_FAVORITE,
	favorite: favorite
});
export const removeFavorite = (favorite) => (dispatch) => dispatch({
	type: REMOVE_FAVORITE,
	favorite: favorite
});

export const addBrokerInformation = (brokerInformation) => (dispatch) => dispatch({
	type: ADD_BROKER_INFORMATION,
	brokerInformation: brokerInformation
});

export const setRealEstateFilters = (filters) => (dispatch) => dispatch({
	type: SET_REALESTATE_FILTERS,
	filters: filters
});
