import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme, Slider, TextField } from '@material-ui/core';
import { Typography, AppBar, Tabs, Tab, Box } from '@material-ui/core';
import * as Yup from 'yup';
import { addLoadingGlobal, removeLoadingGlobal, addMessage } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import ClassContact from 'src/models/ContactModels';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import CardProperty from 'src/components/AnageImoveis/CardProperty';
import KnowMoreForm from './KnowMoreForm';
import SecondaryBanner from 'src/views/pages/Home/SecondaryBanner';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from "simple-react-lightbox";

import Maps from 'src/components/AnageImoveis/MapsBlock';
import GoogleMapReact from 'google-map-react';
import { getWhatsappLink } from 'src/utils/functions';

import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ErrorMessage, Formik, Form as FormikForm, Field } from 'formik';

import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import image7 from 'src/assets/images/o-que-preciso-para-alugar.webp';
import image10 from 'src/assets/images/o-que-preciso-para-alugar-mobile.png';
import image8 from 'src/assets/images/conhecer-bairro-do-imovel.webp';
import image9 from 'src/assets/images/localizacao-imovel-anage.webp'
import home from 'src/assets/images/icons/home.svg';
import map from 'src/assets/images/icons/map.svg';
import whatsapp from 'src/assets/images/icons/whatsapp-white.svg';
import whatsappGreen from 'src/assets/images/icons/whatsapp.svg';
import whatsappBlack from 'src/assets/images/icons/whatsapp-black.svg';
import phone from 'src/assets/images/icons/phone.svg';
import icon1 from 'src/assets/images/icons/expand-square-black.svg';
import icon2 from 'src/assets/images/icons/car-black.svg';
import icon3 from 'src/assets/images/icons/icon-quartos-black.svg';
import icon4 from 'src/assets/images/icons/mail.svg';
import logo from 'src/assets/images/logo-anage.svg';
import like from 'src/assets/images/icons/like-1.svg';
import likeFilled from 'src/assets/images/icons/like-filled.png';
import img from 'src/assets/images/1_4.png';

import { formatPrice, getYoutubeEmbedData } from 'src/utils/functions.js';
import { addFavorite, removeFavorite } from 'src/actions';
import slugify from 'slugify';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

let classContact = new ClassContact();

const defaultFormShape = classContact.getObjects;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
    root: {}
}));

function SingleProperty({ realEstate }) {

    // verifica se é não select
    if (!realEstate.tagSelect)
        window.location = 'https://anageimoveis.com.br/' + realEstate.slug;

    const classes = useStyles();
    const theme = useTheme();
    const { trackEvent } = useAnalytics();

    let savedSearch = "";
    if (sessionStorage.getItem('savedSearch') !== null) {
        savedSearch = sessionStorage.getItem('savedSearch');
    }

    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const brokerInformation = useSelector((state) => state.interface.brokerInformation);

    const [currentTab, setCurrentTab] = useState(0);
    const [showKnowMoreForm, setShowKnowMoreForm] = React.useState(false);
    const [showStorePhone, setShowStorePhone] = React.useState(false);
    const [realEstateBuildings, setRealEstateBuildings] = React.useState([]);
    const [realEstateRelateds, setRealEstateRelateds] = React.useState([]);

    const [VisitStepOne, setVisitStepOne] = useState(false);
    const [VisitStepTwo, setVisitStepTwo] = useState(false);
    const [VisitStepThree, setVisitStepThree] = useState(false);

    const [mapMarkerSize, setMapMarkerSize] = useState(16);


    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const interfaceStore = useSelector((state) => state.interface);

    const realEstateApi = new ManagerApi('/realestate');

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        phone: Yup.string().required('Campo obrigatório.'),
    });

    const styleSuccess = {
        color: 'green',
        textAlign: 'center'
    }

    const styleDeny = {
        color: 'red',
        textAlign: 'center'
    }

    const options = {
        buttons: {
            backgroundColor: '#ffffff',
            iconColor: '#1a1818',
            iconPadding: '10px',
            showAutoplayButton: false,
            showCloseButton: true,
            showDownloadButton: false,
            showFullscreenButton: false,
            showNextButton: true,
            showPrevButton: true,
            showThumbnailsButton: false,
            size: '40px'
        },
        settings: {
            transitionSpeed: 1000,
            transitionTimingFunction: "linear"
        },
        thumbnails: {
            thumbnailsSize: ["168px", "95px"],
            thumbnailsOpacity: 0.4
        },
    }

    const generateGallery = () => {

        let count = 0;
        let slides = [];

        let tempSlides = [];
        realEstate.gallery.map((image) => {
            tempSlides.push(
                <a href={image.image} className="image" style={{ backgroundImage: 'url(' + image.image + ')' }}>
                    <img alt="" src={image.image} />
                </a>
            );

            if (count == 0 || count == realEstate.gallery.length - 1 || count % 2 == 0) {
                slides.push(
                    <SwiperSlide>
                        <div className="image-item">{tempSlides}</div>
                    </SwiperSlide>
                );
                tempSlides = [];
            }
            count++;
        });

        return (
            <SimpleReactLightbox>
                <SRLWrapper options={options}>
                    <div className="property-gallery">
                    <Swiper
                            spaceBetween={5}
                            slidesPerView={1}
                            navigation
                            breakpoints={{
                                768: {
                                    "slidesPerView": 2,
                                },
                                1300: {
                                    "slidesPerView": 3,
                                },
                                1367: {
                                    "slidesPerView": 4,
                                },
                            }}> {slides}
                        </Swiper>
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
        )
    }

    const getAddress = () => {
        let address = [];

        if (realEstate.status !== 'alugar') {
            return '';
        }

        if (realEstate.address) {
            address.push(realEstate.address)
        }
        if (realEstate.addressNumber) {
            address.push("nº " + realEstate.addressNumber)
        }
        // if(realEstate.addressComplement) {
        //     address.push(realEstate.addressComplement)
        // }
        return address.join(', ');
    }

    const getFullAddress = () => {
        let address = [];
        if (realEstate.address) {
            address.push(realEstate.address)
        }
        if (realEstate.addressNumber) {
            address.push("nº " + realEstate.addressNumber)
        }
        // if(realEstate.addressComplement) {
        //     address.push(realEstate.addressComplement)
        // }
        if (realEstate.neighbourhood) {
            address.push(realEstate.neighbourhood)
        }
        if (realEstate.city) {
            address.push(realEstate.city)
        }
        if (realEstate.state) {
            address.push(realEstate.state)
        }
        return address.join(', ');
    }

    const fetchRealEstateBuildings = () => {
        if (realEstate.building != '') {
            realEstateApi.getList({
                building: realEstate.building,
                status: realEstate.status,
                paginator: {
                    pageItemsPerPage: 6
                }
            }).then((response) => {
                if (response.data.success && response.data.content.length) {

                    let filtered = response.data.content.filter((value) => {
                        if (value.id == realEstate.id) {
                            return false;
                        }
                        return true;
                    })

                    setRealEstateBuildings(filtered);
                }
            }).catch((error) => {
            }).then(() => {
            });
        }
    }

    const fetchRealEstateRelateds = () => {
        realEstateApi.getList({
            status: realEstate.status,
            category: realEstate.category ? realEstate.category.id : null,
            minPrice: (realEstate.price * 0.90),
            maxPrice: (realEstate.price * 1.10),
            tagSelect: realEstate.tagSelect,
            tagSeloSelect: realEstate.tagSeloSelect,
            paginator: {
                pageItemsPerPage: 6
            }
        }).then((response) => {
            if (response.data.success && response.data.content.length) {

                let filtered = response.data.content.filter((value) => {
                    if (value.id == realEstate.id) {
                        return false;
                    }
                    return true;
                })

                setRealEstateRelateds(filtered);
            }
        }).catch((error) => {
        }).then(() => {
        });
    }

    const isFavorite = (id) => {
        let found = false;
        for (let i = 0; i < interfaceStore.favorites.length; i++) {
            if (interfaceStore.favorites[i] === id) {
                found = true;
            }
        }
        return found;
    }

    useEffect(() => {
        fetchRealEstateBuildings();
        fetchRealEstateRelateds();
    }, []);

    return (
        <Page
            className={classes.root}
            title={realEstate.name}
            description={realEstate.description}
        >
            <div className="property-content">
                <div className="media-content">
                    <Box>
                        <TabPanel value={currentTab} index={0} dir={theme.direction}>
                            {generateGallery()}
                        </TabPanel>
                        <TabPanel value={currentTab} index={1} dir={theme.direction}>
                            <div className="property-video">
                                <iframe
                                    className="player-item"
                                    src={getYoutubeEmbedData(realEstate.video).url}
                                    frameBorder="0"
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    style={{
                                        width: "100%",
                                        height: "450px"
                                    }}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={currentTab} index={2} dir={theme.direction}>
                            <div className="property-video">
                                <iframe
                                    className="player-item"
                                    src={realEstate.tour360}
                                    frameBorder="0"
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    style={{
                                        width: "100%",
                                        height: "450px"
                                    }}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={currentTab} index={3} dir={theme.direction}>
                            <div className="property-map">

                                {realEstate.status == 'alugar' ? (
                                    <iframe
                                        src={"https://www.google.com/maps/embed/v1/place?key=" + generalInformation.googleMapsKey + "&q=" + getFullAddress()}
                                        allowfullscreen
                                        loading="lazy"
                                        style={{
                                            width: "100%",
                                            height: "450px",
                                            border: "0"
                                        }}
                                    />
                                ) : (
                                    <div style={{ height: '450px', width: '100%' }}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: generalInformation.googleMapsKey }}
                                            options={{
                                                minZoom: 6,
                                                maxZoom: 16
                                            }}
                                            defaultCenter={{
                                                lat: parseFloat(realEstate.latitude),
                                                lng: parseFloat(realEstate.longitude)
                                            }}
                                            defaultZoom={16}
                                            onBoundsChange={(center, zoom) => {
                                                setMapMarkerSize(1.3835 ** zoom);
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: 'rgba(255,0,0,0.5)',
                                                    display: 'block',
                                                    marginLeft: (mapMarkerSize / 2 * -1),
                                                    marginTop: (mapMarkerSize / 2 * -1),
                                                    width: mapMarkerSize,
                                                    height: mapMarkerSize,
                                                    borderRadius: '50%',
                                                }}
                                                lat={parseFloat(realEstate.latitude)}
                                                lng={parseFloat(realEstate.longitude)}
                                            />
                                        </GoogleMapReact>
                                    </div>
                                )}

                                <Formik
                                    initialValues={{ address: '' }}
                                    enableReinitialize
                                    // validationSchema={validationSchema}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={(values, { resetForm, setStatus }) => {
                                        let url = "https://www.google.com/maps/dir/" + values.address + "/" + realEstate.latitude + "," + realEstate.longitude + "/@" + realEstate.latitude + "," + realEstate.longitude + ",15z";
                                        window.open(url);
                                    }}
                                >
                                    {({ handleBlur, handleChange, setFieldValue, values, status }) => (
                                        <FormikForm className="form">
                                            <span className="text text-1">Como chegar?</span>
                                            <div className="form-group">
                                                <label htmlFor="address" className="label-control">Qual seu endereço?</label>
                                                <input type="text" className="form-control" name="address" placeholder="Digite o endereço" value={values.address} onChange={handleChange} />
                                            </div>
                                            <button type="submit" className="btn btn-2">Calcular Rota</button>
                                        </FormikForm>
                                    )}
                                </Formik>

                            </div>
                        </TabPanel>
                        <TabPanel value={currentTab} index={4} dir={theme.direction}>
                            <div className="property-map">
                                <iframe
                                    src={"https://www.google.com/maps/embed/v1/streetview?key=" + generalInformation.googleMapsKey + "&location=" + realEstate.latitude + "," + realEstate.longitude}
                                    allowfullscreen
                                    loading="lazy"
                                    style={{
                                        width: "100%",
                                        height: "450px",
                                        border: "0"
                                    }}
                                />
                            </div>
                        </TabPanel>
                    </Box>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <AppBar position="static" color="default">
                                    <Tabs
                                        value={currentTab}
                                        onChange={handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <Tab label="Fotos" className="photo" />
                                        <Tab label="Vídeo" className="video" style={!realEstate.video ? { display: 'none' } : {}} />
                                        <Tab label="Tour Virtual" className="tour" style={!realEstate.tour360 ? { display: 'none' } : {}} />
                                        <Tab label="Mapa" className="map" style={realEstate.status != 'alugar' && (!realEstate.latitude || !realEstate.longitude) ? { display: 'none' } : {}} />
                                        <Tab label="Street View" className="street" style={!realEstate.latitude || !realEstate.longitude ? { display: 'none' } : {}} />
                                    </Tabs>
                                </AppBar>
                            </div>
                            <div className="col-md-3">
                                <div className="action-favorite">
                                    <button type="button" className="btn btn-favorite" onClick={() => { if (isFavorite(realEstate.id)) { dispatch(removeFavorite(realEstate.id)) } else { dispatch(addFavorite(realEstate.id)) } }} >
                                        <div className="icons">
                                            {isFavorite(realEstate.id) ? (
                                                <img alt="" src={likeFilled} className="icon filled" />
                                            ) : (
                                                <img alt="" src={like} className="icon linear" />
                                            )}
                                        </div>
                                        FAVORITAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="black-band">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="block-info">
                                    <div className="text text-1 text-interest show-mobile">Tem interesse? Fale conosco</div>
                                    <div className="info">
                                        <h4 className="title title-4">
                                            {realEstate.category.name} {realEstate.building ? " - " + realEstate.building : ''}
                                        </h4>
                                        <ul className="list">
                                            <li>
                                                <img alt="" src={icon3} className="icon"></img>
                                                <span>{realEstate.bedrooms} quartos</span>
                                            </li>
                                            <li>
                                                <img alt="" src={icon2} className="icon"></img>
                                                <span>{realEstate.parkingSpaces} vagas</span>
                                            </li>
                                            <li>
                                                <img alt="" src={icon1} className="icon"></img>
                                                <span>{Number.parseFloat(realEstate.area).toFixed(0)} m²</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="info">
                                        <h5 className="text text-5">
                                            Imóvel para {realEstate.status}
                                        </h5>
                                        <h5 className="title title-4 title-yellow">
                                            {formatPrice(realEstate.status == 'comprar' ? realEstate.priceSale : realEstate.priceRental)}
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="block-info">
                                    {realEstate.status == 'alugar' && (
                                        <Link href={"https://anage.bitrix24.site/prevenda/agendar_visita/?CodIm="+realEstate.code} target="_blank" className="btn btn-1">
                                            <img alt="" src={home} className="icon"></img>
                                            Agendar Visita
                                        </Link>
                                    )}
                                    {!brokerInformation && realEstate.store && (
                                        <a href={getWhatsappLink("47999680934")} target="_blank" className="btn btn-whats-small"
                                            onClick={() => { trackEvent('Clique no botão', 'Whatsapp '+realEstate.status, '47999680934') }}
                                        >
                                            <img alt="" src={whatsapp} className="icon"></img>
                                        </a>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="description">
                                    <div className="details">

                                        <div className="row">
                                            <div className="col-md-8">
                                                <h1 className="title title-10 name">
                                                    <div className="text text-1 location">
                                                        {realEstate.neighbourhood} | {realEstate.city} - {realEstate.state}
                                                    </div>
                                                    {realEstate.category.name} {realEstate.status == 'alugar' && realEstate.building ? " - " + realEstate.building : ''}
                                                </h1>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="block-address">
                                                    <p className="text text-6">
                                                        {getAddress()}
                                                    </p>
                                                    <p className="text text-2">
                                                        Ref: {realEstate.code}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="highlights">
                                        <div className="highlight">
                                            <img alt="" src={icon3} className="icon"></img>
                                            <span className="text text-1">{realEstate.bedrooms} quartos ({realEstate.suites} suítes)</span>
                                        </div>
                                        <div className="highlight">
                                            <img alt="" src={icon2} className="icon"></img>
                                            <span className="text text-1">{realEstate.parkingSpaces} vagas de garagem</span>
                                        </div>
                                        {realEstate.totalArea && (
                                            <div className="highlight">
                                                <img alt="" src={icon1} className="icon"></img>
                                                <span className="text text-1">Área total: {Number.parseFloat(realEstate.totalArea).toFixed(0)} m²</span>
                                            </div>
                                        )}
                                        {realEstate.privateArea && (
                                            <div className="highlight">
                                                <img alt="" src={icon1} className="icon"></img>
                                                <span className="text text-1">Área privativa: {Number.parseFloat(realEstate.privateArea).toFixed(0)} m²</span>
                                            </div>
                                        )}
                                        {realEstate.landArea && (
                                            <div className="highlight">
                                                <img alt="" src={icon1} className="icon"></img>
                                                <span className="text text-1">Área terreno: {Number.parseFloat(realEstate.landArea).toFixed(0)} m²</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="show-mobile">
                                        <div className="sidebar">
                                            <div className="block-info">

                                                <p className="text property-type">
                                                    Imóvel para {realEstate.status}
                                                </p>

                                                <p className="title title-2">
                                                    {realEstate.status === 'comprar' && realEstate.priceSaleSpecial > 0 ? (
                                                        <>
                                                            <span className="priceOld">{formatPrice(realEstate.priceSale)}</span>
                                                            <span className="price">{formatPrice(realEstate.priceSaleSpecial)}</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="price">{formatPrice(realEstate.status === 'comprar' ? realEstate.priceSale : realEstate.priceRental)}</span>
                                                        </>
                                                    )}
                                                </p>
                                                {/* <p className="text text-1">
                                                    Carência de Condomínio
                                                </p> */}

                                                {realEstate.status == 'alugar' && (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <p className="text text-1">
                                                                    Condomínio:
                                                                </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className="text text-1 text-right">
                                                                    {formatPrice(realEstate.priceCondominium)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <p className="text text-1">
                                                                    IPTU:
                                                                </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className="text text-1 text-right">
                                                                    {formatPrice(realEstate.priceIptu)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <div className="col-7">
                                                                <p className="text text-1">
                                                                    TLU:
                                                                </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className="text text-1 text-right">
                                                                    {formatPrice(realEstate.priceOther)}
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col-7">
                                                                <p className="text text-1">
                                                                    <span>Total:</span>
                                                                </p>
                                                            </div>
                                                            <div className="col-5">
                                                                <p className="text text-1 text-right">
                                                                    <span>{formatPrice(parseFloat(realEstate.priceRental) + parseFloat(realEstate.priceCondominium) + parseFloat(realEstate.priceIptu) + parseFloat(realEstate.priceOther))}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="block-actions">
                                                {showKnowMoreForm ? (
                                                    <>
                                                        <button type="button" className="btn btn-voltar btn-3" onClick={() => { setShowKnowMoreForm(!showKnowMoreForm) }}>
                                                            <i></i>Voltar
                                                        </button>
                                                        <Fade in={showKnowMoreForm}>
                                                            <div>
                                                                <KnowMoreForm realEstate={realEstate} />
                                                            </div>
                                                        </Fade>
                                                    </>
                                                ) : (
                                                    <>
                                                        {realEstate.status == 'alugar' && (
                                                            <Link href={"https://anage.bitrix24.site/prevenda/agendar_visita/?CodIm=" + realEstate.code+savedSearch} target="_blank" className="btn btn-1">
                                                                <img alt="" src={home} className="icon"></img>
                                                                Agendar Visita
                                                            </Link>
                                                        )}

                                                        {!brokerInformation && realEstate.store && (
                                                            <>
                                                                <button type="button" className="btn btn-3" onClick={() => setShowStorePhone(true)}>
                                                                    <img alt="" src={phone} className="icon"></img>
                                                                    {showStorePhone ? (
                                                                        <a href={"tel:" + realEstate.store.phone}>{realEstate.store.phone}</a>
                                                                    ) : (
                                                                        'Telefone da Loja'
                                                                    )}
                                                                </button>
                                                                <a href={getWhatsappLink("47999680934")} target="_blank" className="btn btn-3"
                                                                    onClick={() => { trackEvent('Clique no botão', 'Whatsapp ' + realEstate.status, '47999680934') }}
                                                                >
                                                                    <img alt="" src={whatsappBlack} className="icon"></img>
                                                                    Fale pelo WhatsApp
                                                                </a>
                                                            </>
                                                        )}

                                                        <button type="button" className="btn btn-2" onClick={() => { setShowKnowMoreForm(!showKnowMoreForm) }}>
                                                            Quero saber mais
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="shared-block">
                                            <div className="shared">
                                                <span className="text text-6">Compartilhar Imóvel</span>
                                                <ul className="list">
                                                    <li>
                                                        <a href="#">
                                                            <img alt="" src={whatsappBlack} className="icon-whatsapp"></img>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <img alt="" src={icon4} className="icon"></img>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            {realEstate.status == 'alugar' && (
                                                <p className="text text-7">
                                                    * Os valores de condomínio e taxas são aproximados. Além do aluguel, será de responsabilidade do locatário o pagamento do IPTU, condomínio, TLU, seguro contra incêndio, e demais encargos estabelecidos no contrato de locação, conforme artigos 22 e 23 da lei 8.245/91.”
                                                </p>
                                            )}
                                        </div> */}
                                    </div>
                                    <div className="info">
                                        <h4 className="title title-9">
                                            Sobre esse imóvel
                                        </h4>
                                        <div className="block-text">
                                            <div dangerouslySetInnerHTML={{ __html: realEstate.description }}></div>
                                        </div>
                                        <div className="features">
                                            {realEstate.alarm == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Alarme:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.electricHeating == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Aquecimento Elétrico:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.gasHeating == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Aquecimento Gás:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.solarHeating == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Aquecimento Solar:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.bathtub == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Banheira:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.auxiliaryBathroom && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Banheiro Auxiliar:</span> {realEstate.auxiliaryBathroom}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.electricFence == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Cerca Elétrica:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.barbecueOnTheBalcony == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Churrasqueira na sacada:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.barbecueGrill == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Churrasqueira:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.closet != 0 && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Closet:</span> {realEstate.closet}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.numberPantry != 0 && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Número de Copas:</span> {realEstate.numberPantry}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.kitchen == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Cozinha:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.deck == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Deck:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.pantry == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Despensa:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.cottage == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Edícula:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.splitWait == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Espera. Split:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.homeOffice == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Home Office:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.homeTheater == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Home Theater:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.fireplace == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Lareira:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.powderRoom && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Lavabo:</span> {realEstate.powderRoom}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.livingRoom && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Sala de estar:</span> {realEstate.livingRoom}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.individualWaterMeasurement == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Medição individual de água:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.individualGasMeasurement == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Medição individual de gás:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.myHouseMyLife == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Minha casa minha vida:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.furnished == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Mobiliado:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.pool == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Piscina:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.floor && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Piso:</span> {realEstate.floor}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.electronicGate == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Portão Eletrônico:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.serviceRoom != 0 && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Número de quartos de serviço:</span> {realEstate.serviceRoom}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.renovated == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Reformado:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.balcony && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Sacada:</span> {realEstate.balcony}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.diningRoom && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Número de salas de jantar:</span> {realEstate.diningRoom}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.tvRoom != 0 && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Número de salas de tv:</span> {realEstate.tvRoom}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.sauna == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Sauna:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.semiFurnished == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Semimobiliado:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.spa == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Spa:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.split == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Split:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.suiteMaster == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Suíte Master:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.terrace == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Terraço:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.panoramicView == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Vista Panorâmica:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="info">
                                        <h4 className="title title-9">
                                            Características do condomínio
                                        </h4>
                                        <div className="features">
                                            {realEstate.academyFitness == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Academia:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.bikeRack == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Bicicletário:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.playroom == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Brinquedoteca:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.condominiumGrill == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Churrasqueira no condomínio:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.penthouse == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Cobertura:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.deposit == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Depósito:</span>  sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.elevator == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Elevador:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.gourmetSpace == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Espaço Gourmet:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.womanSpace == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Espaço Mulher:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.centralGas == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Gás Central:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.homeCinema == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Home Cinema:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.garden == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Jardim:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.laundry == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Lavanderia:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.shopsBuilding != 0 && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Lojas Edifício:</span> {realEstate.shopsBuilding}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.petcare == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Petcare:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.adultPool == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Piscina para adultos:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.heatedPool == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Piscina aquecida:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.childrensPool == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Piscina para crianças:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.numberPool != 0 && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Número de piscinas:</span> {realEstate.numberPool}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.playground == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Playground:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.concierge24hour == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Portaria 24h:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.intercomElectronic == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Porteiro Eletrônco:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.sportsCourt == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Quadra de esportes:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.tennisCourt == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Quadra de tênis:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.kiosk == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Quiósque:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.fitnessRoom == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Sala fitness:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.gamesRoom == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Sala de jogos:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.partyRoom == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Salão de festas:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.meetingRoom == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Sala de reuniões:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.buildingRooms != 0 && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Salas de edifícios:</span> {realEstate.buildingRooms}
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.saunacol == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Sauna coletiva:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.spaInfra == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Spa infra:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.collectiveTerrace == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Terraço coletivo:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.nightWatcher == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Vigia noturno:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.surveillance24hour == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Vigilância 24h:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                            {realEstate.caretaker == true && (
                                                <div className="feature">
                                                    <p className="text text-1">
                                                        <span>Zelador:</span> sim
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="small-banner" style={{ backgroundImage: 'url(' + image8 + ')' }}>
                                        <a href={ "/joinville/"+(slugify(realEstate.neighbourhood.toLowerCase())) } className="btn btn-1" target="_blank">Conhecer o bairro</a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-4 offset-md-1 hidden-mobile">
                                <div className="sidebar">
                                    <div className="block-info">

                                        <p className="text property-type">
                                            Imóvel para {realEstate.status}
                                        </p>

                                        <p className="title title-2">
                                            {realEstate.status === 'comprar' && realEstate.priceSaleSpecial > 0 ? (
                                                <>
                                                    <span className="priceOld">{formatPrice(realEstate.priceSale)}</span>
                                                    <span className="price">{formatPrice(realEstate.priceSaleSpecial)}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="price">{formatPrice(realEstate.status === 'comprar' ? realEstate.priceSale : realEstate.priceRental)}</span>
                                                </>
                                            )}
                                        </p>
                                        {/* <p className="text text-1">
                                            Carência de Condomínio
                                        </p> */}

                                        {realEstate.status == 'alugar' && (
                                            <>
                                                <div className="row">
                                                    <div className="col-7">
                                                        <p className="text text-1">
                                                            Condomínio:
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <p className="text text-1 text-right">
                                                            {formatPrice(realEstate.priceCondominium)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-7">
                                                        <p className="text text-1">
                                                            IPTU:
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <p className="text text-1 text-right">
                                                            {formatPrice(realEstate.priceIptu)}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-7">
                                                        <p className="text text-1">
                                                            TLU:
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <p className="text text-1 text-right">
                                                            {formatPrice(realEstate.priceOther)}
                                                        </p>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-7">
                                                        <p className="text text-1">
                                                            <span>Total:</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-5">
                                                        <p className="text text-1 text-right">
                                                            <span>{formatPrice(parseFloat(realEstate.priceRental) + parseFloat(realEstate.priceCondominium) + parseFloat(realEstate.priceIptu) + parseFloat(realEstate.priceOther))}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="block-actions">
                                        {showKnowMoreForm ? (
                                            <>
                                                <button type="button" className="btn btn-voltar btn-3" onClick={() => { setShowKnowMoreForm(!showKnowMoreForm) }}>
                                                    <i></i>Voltar
                                                </button>
                                                <Fade in={showKnowMoreForm}>
                                                    <div>
                                                        <KnowMoreForm realEstate={realEstate} />
                                                    </div>
                                                </Fade>
                                            </>
                                        ) : (
                                            <>
                                                {realEstate.status == 'alugar' && (
                                                    <Link href={"https://anage.bitrix24.site/prevenda/agendar_visita/?CodIm=" + realEstate.code+savedSearch} target="_blank" className="btn btn-1">
                                                        <img alt="" src={home} className="icon"></img>
                                                        Agendar Visita
                                                    </Link>
                                                )}

                                                {!brokerInformation && realEstate.store && (
                                                    <>
                                                        <button type="button" className="btn btn-3" onClick={() => setShowStorePhone(true)}>
                                                            <img alt="" src={phone} className="icon"></img>
                                                            {showStorePhone ? (
                                                                <a href={"tel:" + realEstate.store.phone}>{realEstate.store.phone}</a>
                                                            ) : (
                                                                'Telefone da Loja'
                                                            )}
                                                        </button>
                                                        <a href={getWhatsappLink("47999680934")} target="_blank" className="btn btn-3"
                                                            onClick={() => { trackEvent('Clique no botão', 'Whatsapp ' + realEstate.status, '47999680934') }}
                                                        >
                                                            <img alt="" src={whatsappBlack} className="icon"></img>
                                                            Fale pelo WhatsApp
                                                        </a>
                                                    </>
                                                )}

                                                <button type="button" className="btn btn-2" onClick={() => { setShowKnowMoreForm(!showKnowMoreForm) }}>
                                                    Quero saber mais
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* <div className="shared-block">
                                    <div className="shared">
                                        <span className="text text-5">Compartilhar Imóvel</span>
                                        <ul className="list">
                                            <li>
                                                <a href="#">
                                                    <img alt="" src={whatsappBlack} className="icon-whatsapp"></img>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img alt="" src={icon4} className="icon"></img>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {realEstate.status == 'alugar' && (
                                        <p className="text text-7">
                                            * Os valores de condomínio e taxas são aproximados. Além do aluguel, será de responsabilidade do locatário o pagamento do IPTU, condomínio, TLU, seguro contra incêndio, e demais encargos estabelecidos no contrato de locação, conforme artigos 22 e 23 da lei 8.245/91.”
                                        </p>
                                    )}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                {realEstateRelateds && realEstateRelateds.length > 0 && (
                    <div className="property-list featured-properties gray">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="block-title">
                                        <h3 className="title title-9">
                                            Imóveis Similares
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Swiper
                                        spaceBetween={30}
                                        slidesPerView={1}
                                        pagination
                                    >
                                        {realEstateRelateds.map((realEstateRelated) => {
                                            return (
                                                <SwiperSlide>
                                                    <CardProperty realEstate={realEstateRelated} />
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <SecondaryBanner />
            </div>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="visit-dialog">
                {!VisitStepTwo && (
                    <div id="block-visit-1" className="block-visit block-visit-1">
                        <h3 className="title title-5">Imóvel: <span className="title title-8">#{realEstate.code} {realEstate.category.name} - {realEstate.building}…</span></h3>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            <h2 className="title title-3">Escolha a data e horário da sua visita</h2>
                        </DialogTitle>
                        <DialogContent>
                            <p className="text text-1">
                                Outubro 2020
                            </p>
                            <form className="form" action="#" method="post">
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={3}
                                    navigation
                                >
                                    <SwiperSlide>
                                        <button type="button" className="btn btn-visit">
                                            <p className="title title-13">17</p>
                                            <p className="text text-1">Domingo</p>
                                        </button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button type="button" className="btn btn-visit">
                                            <p className="title title-13">18</p>
                                            <p className="text text-1">Segunda</p>
                                        </button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button type="button" className="btn btn-visit">
                                            <p className="title title-13">19</p>
                                            <p className="text text-1">Terça</p>
                                        </button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button type="button" className="btn btn-visit">
                                            <p className="title title-13">20</p>
                                            <p className="text text-1">Quarta</p>
                                        </button>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <button type="button" className="btn btn-visit">
                                            <p className="title title-13">21</p>
                                            <p className="text text-1">Quinta</p>
                                        </button>
                                    </SwiperSlide>
                                </Swiper>
                                <div className="form-group normal-group">
                                    <p className="text text-1">Horário</p>
                                    <select className="form-control" id="hourSelect">
                                        <option>11:00</option>
                                    </select>
                                </div>
                                <button type="button" className="btn btn-1" onClick={() => setVisitStepTwo(!VisitStepTwo)}>Avançar</button>
                            </form>
                        </DialogContent>
                    </div>
                )}
                {VisitStepTwo && (
                    !VisitStepThree && (
                        <div id="block-visit-2" className="block-visit block-visit-2">
                            <h3 className="title title-5">Imóvel: <span className="title title-8">#{realEstate.code} {realEstate.category.name} - {realEstate.building}…</span></h3>
                            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                <h2 className="title title-3">Seus Dados</h2>
                            </DialogTitle>
                            <DialogContent>
                                <form className="form" action="#" method="post">
                                    <div className="form-group">
                                        <label htmlFor="brokerForm-name" className="label-control">Nome</label>
                                        <input type="text" className="form-control" id="brokerForm-name" name="name" placeholder="Digite seu nome" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="brokerForm-email" className="label-control">E-mail</label>
                                        <input type="text" className="form-control" id="brokerForm-email" name="email" placeholder="exemplo@email.com.br" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="brokerForm-phone" className="label-control">DDD + Telefone/WhatsApp</label>
                                        <input type="text" className="form-control" id="brokerForm-phone" name="phone" placeholder="(00) 00000-00000" />
                                    </div>
                                    <div className="custom-control custom-checkbox small-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="privacyPolicy" />
                                        <label className="custom-control-label" htmlFor="privacyPolicy">Ao fornecer meus dados eu concordo em receber comunicações de acordo com a <RouterLink to="/politica-de-privacidade">Política de Privacidade.</RouterLink></label>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            
                                        </div>
                                        <div className="col-md-7">
                                            <button type="button" className="btn btn-1" onClick={() => setVisitStepThree(!VisitStepThree)}>Avançar</button>
                                        </div>
                                    </div>
                                </form>
                            </DialogContent>
                        </div>
                    ))}
                {VisitStepThree && (
                    <div id="block-visit-3" className="block-visit block-visit-3">
                        <h3 className="title title-5">Imóvel: <span className="title title-8">#{realEstate.code} {realEstate.category.name} - {realEstate.building}…</span></h3>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>

                        </DialogTitle>
                        <DialogContent>
                            <div className="block-success">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img alt="" src={logo} className="img-logo"></img>
                                        <h4 className="title title-14">Pré-agendamento realizado com sucesso!</h4>
                                        <p className="text text-1">
                                            Em breve um profissional entrará em contato para confirmar e dar mais detalhes.
                                        </p>
                                        <button type="button" className="btn btn-1">Finalizar</button>
                                    </div>
                                    <div className="col-md-5 offset-md-1">
                                        <h4 className="title title-3">Resumo</h4>
                                        <p className="text text-1">
                                            <span>Nome:</span> Mateus Toignal de Oliveira
                                        </p>
                                        <p className="text text-1">
                                            <span>Data:</span> 18 de outubro
                                        </p>
                                        <p className="text text-1">
                                            <span>Horário:</span> 11:00
                                        </p>
                                        <a href="" className="btn btn-9">Adicionar ao calendário</a>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                )}
            </Dialog>

        </Page>
    );
}

export default SingleProperty;
