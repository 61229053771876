import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
// import MainProperties from './MainProperties';
import MainProperties from './MainProperties';
import Categories from './Categories';
import SecondaryBanner from './SecondaryBanner';
import InfoModalities from './InfoModalities';
import InterestSearchBlock from 'src/components/AnageImoveis/InterestSearchBlock';
import AdInfo from './AdInfo';
import SearchBlock from 'src/components/AnageImoveis/SearchBlock';
import Blog from 'src/components/AnageImoveis/Blog';
import Newsletter from 'src/components/AnageImoveis/Newsletter';

import banner from 'src/assets/images/anage-imobiliaria-joinville.webp';

const useStyles = makeStyles(() => ({
    root: {}
}));

function Home() {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Anagê Imóveis"
        >
            <MainProperties />
            <Categories />
            <SecondaryBanner />
            <AdInfo />
        </Page>
    );
}

export default Home;
