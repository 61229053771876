import React, { useState, useEffect } from 'react';
import {Link as RouterLink} from 'react-router-dom';
import { useSelector } from 'react-redux';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ManagerApi from 'src/services/managerApi';

import arrow from 'src/assets/images/icons/ionic-ios-arrow-forward.svg';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function FullWidthTabs() {
    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
        setValue(index);
    };
  
    return (
        <div className="featured-categories">
            <div className="container">
                <div className="block">
                    <h2 className="title title-10">
                        CURADORIA ANAGÊ SELECT
                    </h2>
                    <p className="text text-1">
                        O poder de realizar novas escolhas: inspire-se com nossa seleção de imóveis alto padrão em Joinville
                    </p>
                </div>
                <div className="block-categories">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={2}
                        breakpoints={{
                            768: {
                              slidesPerView: 5,
                            },
                        }}
                    >
                        {generalInformation.suggestions['select'].length > 0 && generalInformation.suggestions['select'].map((category, i) => {
                            let url = category.slug;
                            let urlSplit = url.split('http');
                            if (urlSplit.length > 1) {
                                return (
                                    <SwiperSlide>
                                        <a href={url} class="category" target='_blank' style={{ backgroundImage: 'url(' + category.image + ')' }}>
                                            <p className="text text-8">
                                                <img alt="" src={arrow} className="icon"></img>
                                                {category.buttonName}
                                            </p>
                                        </a>
                                    </SwiperSlide>
                                )
                            } else {
                                return (
                                    <SwiperSlide>
                                        <RouterLink to={category.slug} key={category.i} className="category" style={{ backgroundImage: 'url(' + category.image + ')' }}>
                                            <p className="text text-8">
                                                <img alt="" src={arrow} className="icon"></img>
                                                {category.buttonName}
                                            </p>
                                        </RouterLink>
                                    </SwiperSlide>
                                )
                            }
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}
