import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';

import { useDispatch } from 'react-redux';
import { addLoadingGlobal, removeLoadingGlobal, addMessage } from 'src/actions';
import { ErrorMessage, Formik, Form as FormikForm, Field, Label } from 'formik';
import * as Yup from 'yup';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';
import ClassContact from 'src/models/AdvertisePropertyModels';

import speed from 'src/assets/images/icons/icon-velocidade.svg';
import notebook from 'src/assets/images/icons/icon-divulgacao.svg';
import agility from 'src/assets/images/icons/icon-agilidade.svg';
import security from 'src/assets/images/icons/icon-seguranca.svg';
import attendance from 'src/assets/images/icons/icon-atendimento-2.svg';
import iso from 'src/assets/images/icons/icon-certificacao.svg';

import background from 'src/assets/images/topo-anunciar-imovel.png';
import woman from 'src/assets/images/woman-calculadora-aluguel-anage.png';
import whatsapp from 'src/assets/images/icons/whatsapp.svg';

import { getWhatsappLink } from 'src/utils/functions';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

let classContact = new ClassContact();
const defaultFormShape = classContact.getObjects;

const useStyles = makeStyles(() => ({
    root: {}
}));

function FindProperty() {
    const classes = useStyles();
    const { trackEvent } = useAnalytics();
    const dispatch = useDispatch();
    const contactApi = new ManagerApi('/contact/announcerrealestate');

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório.'),
        email: Yup.string().required('Campo obrigatório.'),
        phone: Yup.string().required('Campo obrigatório.'),
        status: Yup.string().required('Campo obrigatório.'),
        neighbourhood: Yup.string().required('Campo obrigatório.'),
        type: Yup.string().required('Campo obrigatório.'),
    });
    
    const styleSuccess = {
        color:'green',
        textAlign:'center'
    }

    const styleDeny = {
        color:'red',
        textAlign:'center'
    }

    let pageTitle = 'Anuncie seu Imóvel';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >
            <div className="advertise-property" id="quero-anunciar">
                <div className="page-header" style={{ backgroundImage: 'url(' + background + ')' }}></div>
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="description">
                                            <h1 className="title title-1">
                                                Anunciar imóvel em Joinville
                                            </h1>
                                            <div className="info">
                                                <p className="text text-1">
                                                    Proprietário, anuncie seu imóvel - para vender ou alugar - com a Anagê. Aqui, nós te ajudamos a fechar o melhor negócio! Preencha o formulário ao lado com seus dados pessoais e os dados do seu imóvel. Entraremos em contato com você!
                                                </p>
                                            </div>
                                            <h2 className="title title-2">
                                                Como funciona
                                            </h2>
                                            <div className="steps">
                                                <div className="item">
                                                    <div className="step">
                                                        1
                                                    </div>
                                                    <p className="text text-1">
                                                        Preencha o formulário com seus dados e os dados do imóvel.
                                                    </p>
                                                </div>
                                                <div className="item">
                                                    <div className="step">
                                                        2
                                                    </div>
                                                    <p className="text text-1">
                                                        Nossos especialistas entrarão em contato em breve para mais informações e detalhes do imóvel.
                                                    </p>
                                                </div>
                                                <div className="item">
                                                    <div className="step">
                                                        3
                                                    </div>
                                                    <p className="text text-1">
                                                        Seu imóvel é incluído nos nossos sistemas e publicado no site da Anagê e nos principais portais imobiliários do país.
                                                    </p>
                                                </div>
                                            </div>
                                            <a href={getWhatsappLink("47999680934")} target="_blank" className="btn btn-whats"
                                                onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', '47999680934') }}
                                            >
                                                <img alt="" src={whatsapp} className="icon"></img>
                                                Fale pelo Whatsapp
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="block-form">
                                            <Formik
                                                initialValues={defaultFormShape}
                                                enableReinitialize
                                                validationSchema={validationSchema}
                                                validateOnChange={false}
                                                validateOnBlur={false}
                                                onSubmit={(values , {resetForm, setStatus}) => {

                                                    let data = new FormData();
                                                    data = values;

                                                    if(!values.acceptPolicy) {
                                                        dispatch(addMessage('Para prosseguir, você precisa aceitar os termos de envio.', 'error'));
                                                        return;
                                                    }

                                                    dispatch(addLoadingGlobal());
                                                    contactApi.post(data).then(response => {
                                                        dispatch(removeLoadingGlobal());

                                                        if (response.data.success == true) {
                                                            dispatch(addMessage('Registro realizado com sucesso.', 'success'));
                                                            resetForm();
                                                            trackEvent('Envio de formulário', 'Pré-cadastro de proprietario', '')
                                                        }
                                                        else {
                                                            dispatch(addMessage('Não foi possível enviar o contato, por favor tente novamente mais tarde.', 'error'));
                                                        }
                                                    });
                                                    
                                                }}
                                            >
                                                { ({ handleBlur, handleChange, setFieldValue, status, values }) => (
                                                <FormikForm className="form">
                                                    <h3 className="title title-3">Faça o pré-cadastro do seu imóvel</h3>
                                                    <h4 className="title title-4">Dados Pessoais</h4>
                                                    <div className="form-group">
                                                        <label htmlFor="announcerForm-name" className="label-control">Nome</label>
                                                        <Field type="text" className="form-control" id="announcerForm-name" onBlur={handleBlur} onChange={handleChange} name="name" placeholder="Digite seu nome" />
                                                        <ErrorMessage component="span" className="error-message" name="name" />
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="announcerForm-email" className="label-control">E-mail</label>
                                                                <Field type="email" className="form-control" id="announcerForm-email" onBlur={handleBlur} onChange={handleChange} name="email" placeholder="exemplo@email.com.br" />
                                                                <ErrorMessage component="span" className="error-message" name="name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="announcerForm-phone" className="label-control">DDD + Telefone/WhatsApp</label>
                                                                <Field type="text" className="form-control" id="announcerForm-phone" onBlur={handleBlur} onChange={handleChange} name="phone" placeholder="(00) 00000-00000" />
                                                                <ErrorMessage component="span" className="error-message" name="name" />
                                                            </div>  
                                                        </div>
                                                    </div>
                                                    <h4 className="title title-4">Dados do imóvel</h4>
                                                    <div className="form-group">
                                                        <label htmlFor="advertiseModalitySelect" className="label-control">Finalidade do imóvel</label>
                                                        <Field name="status" as="select" className="form-control custom-select" id="advertiseModalitySelect">
                                                            <option value="">Selecione a finalidade</option>
                                                            <option value="Aluguel">Aluguel</option>
                                                            <option value="Venda">Venda</option>
                                                        </Field>
                                                        <ErrorMessage component="span" className="error-message" name="status" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="city" className="label-control">Cidade do imóvel</label>
                                                        <Field type="text" className="form-control" id="city" onBlur={handleBlur} onChange={handleChange} name="city" placeholder="Digite a cidade do imóvel" />
                                                        <ErrorMessage component="span" className="error-message" name="city" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="advertiseDistrictSelect" className="label-control">Bairro do imóvel</label>
                                                        <Field type="text" className="form-control" id="advertiseDistrictSelect" onBlur={handleBlur} onChange={handleChange} name="neighbourhood" placeholder="Digite o bairro do imóvel" />
                                                        <ErrorMessage component="span" className="error-message" name="neighbourhood" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="advertiseTypeSelect" className="label-control">Tipo do imóvel</label>
                                                        <Field type="text" className="form-control" id="advertiseTypeSelect" onBlur={handleBlur} onChange={handleChange} name="type" placeholder="Digite o tipo do imóvel" />
                                                        <ErrorMessage component="span" className="error-message" name="type" />
                                                    </div>
                                                    <div className="custom-control custom-checkbox small-checkbox" onClick={ () => { setFieldValue('acceptPolicy',!values.acceptPolicy) } }>
                                                        <input type="checkbox" className="custom-control-input" name="acceptPolicy" checked={values.acceptPolicy} />
                                                        <label className="custom-control-label">Ao preencher este formulário concordo com a coleta e tratamento dos meus dados, conforme <RouterLink to="/PolticadePrivacidadeAnage.pdf" target="_blank">Política de Privacidade</RouterLink>, nos termos da Lei 13.709/2018, permitindo desde já eventual armazenamento destes dados e o contato comercial da Anagê Imóveis</label>
                                                    </div>
                                                    {status && status.msg && (
                                                        <p style={status.sent == true ? styleSuccess : styleDeny}>
                                                            {status.msg}
                                                        </p>
                                                    )}
                                                    <button className="btn btn-1">Enviar</button>
                                                </FormikForm>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="over">
                                    <div className="item">
                                        <img alt="" src={woman} className="img"></img>
                                        <div className="triangle"></div>
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="block-info">
                                                    <div className="block">
                                                        <h3 className="title title-1">
                                                            Calculadora<br/> de Aluguel Anagê
                                                        </h3>
                                                        <p className="text text-1 show-mobile">
                                                            Você é proprietário de imóvel? Alugue e faça seu dinheiro render!
                                                        </p>
                                                        <RouterLink to="/calculadora-de-aluguel" className="btn btn-5">
                                                            Calcular Rentabilidade
                                                        </RouterLink>
                                                    </div>
                                                    <div className="block hidden-mobile">
                                                        <p className="text text-1">
                                                            Você é proprietário de imóvel? Alugue e faça seu dinheiro render!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reasons">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="block-title">
                                            <h3 className="title title-2">
                                                Razões para escolher a Anagê Imóveis
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={speed} className="icon"></img>
                                            <p className="text text-1">
                                                <span>Velocidade: </span>
                                                    a imobiliária que mais aluga e vende em Joinville
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={notebook} className="icon"></img>
                                            <p className="text text-1">
                                                <span>Divulgação: </span>
                                                    no site da Anagê e nos principais portais do país com fotos profissionais
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={agility} className="icon"></img>
                                            <p className="text text-1">
                                                <span>Agilidade: </span>
                                                    negociação e assinatura do contrato online
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={security} className="icon"></img>
                                            <p className="text text-1">
                                                <span>Segurança: </span>
                                                    processos certificados e mais de 30 anos fazendo negócios imobiliários em Joinville
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={attendance} className="icon"></img>
                                            <p className="text text-1">
                                                <span>Atendimento personalizado: </span>
                                                    pessoalmente nas nossas lojas ou de forma digital
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="item">
                                            <img alt="" src={iso} className="icon"></img>
                                            <p className="text text-1">
                                                <span>Certificação ISO 9001: </span>
                                                    o selo comprova o compromisso e a eficácia da Política de Qualidade Anagê Imóveis
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="action">
                                            <a href="#quero-anunciar" className="btn btn-1">Quero Anunciar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
        </Page>
    );
}

export default FindProperty;
