import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import { Link as RouterLink } from 'react-router-dom';
import ManagerApi from 'src/services/managerApi';
import { useAnalytics } from 'src/hooks/useAnalytics';

import refresh from 'src/assets/images/icons/refresh.svg';
import whatsapp from 'src/assets/images/icons/whatsapp.svg';

import { formatPrice, getWhatsappLink } from 'src/utils/functions.js';
import background from 'src/assets/images/calculadora-aluguel-anage.webp';

const useStyles = makeStyles(() => ({
    root: {}
}));

function FindProperty({history}) {
    const classes = useStyles();
    const { trackEvent } = useAnalytics();
    const [contact, setContact] = useState(false);

    const contactApi = new ManagerApi('/contact/rentalcalculator');

    const fetchContact = (contactId) => {
        contactApi.get(contactId).then((response) => {
            if (response.data.success && response.data.content?.id) {
                setContact(response.data.content);
            }
            else {
                history.push('/calculadora-de-aluguel');
            }
        }).catch((error) => {
        }).then(() => {
        });
    }
    
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if(!queryParams.get('id')) {
            history.push('/calculadora-de-aluguel');
        }
        fetchContact(queryParams.get('id'));
    }, []);

    if(!contact) {
        return (<></>);
    }

    let priceRentalMin = formatPrice(contact.priceRentalMin);
    let priceRentalMax = formatPrice(contact.priceRentalMax);

    let monthlyCost = (parseFloat(contact.iptuValue ?? 0) + parseFloat(contact.condominiumValue ?? 0));

    let yearlyRental = contact.priceRentalAvg * 12;
    let yearlyCost = monthlyCost * 12;
    let yearlyBalance = yearlyRental + yearlyCost;

    return (
        <Page
            className={classes.root}
            title="Calculadora de Aluguel Resultado"
        >
            <div className="rental-calculator result">
                <div className="page-header" style={{ backgroundImage: 'url(' + background + ')' }}>
                    <div className="description">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 offset-md-1">
                                    <h3 className="title title-3">
                                        Olá, {contact.name}! Saiba quanto seu imóvel pode gerar de retorno, de acordo com nosso banco de dados.
                                    </h3>
                                    <h4 className="title title-7">
                                        O valor do retorno apresentado é estimado e refere-se ao período de 1 mês*:
                                    </h4>
                                    {contact.priceRentalMin ? (
                                      <>
                                        <p className="prices">
                                            {priceRentalMin.split(',')[0]}<small>,{priceRentalMin.split(',')[1]}</small><br/><small> a </small>{priceRentalMax.split(',')[0]}<small>,{priceRentalMax.split(',')[1]}</small>
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p className="title title-3" style={{fontSize: '14px', lineHeight: '1.5em'}}>
                                            Infelizmente não encontramos imóveis semelhantes. Por favor, entre em contato conosco para um atendimento personalizado.
                                        </p>
                                      </>
                                    )}
                                    <div className="action">
                                        <a href="#resultados-detalhados" className="btn btn-7">
                                            Ver resultados detalhados
                                        </a>
                                        <RouterLink to="/calculadora-de-aluguel" className="btn btn-1">
                                            Calcular Retorno do Investimento    
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content" id="resultados-detalhados">
                    <div className="result-info">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="description">
                                                <h3 className="title title-2">
                                                    Resultados:
                                                </h3>
                                                <div className="steps">
                                                    <div className="item">
                                                        <div className="step">1</div>
                                                        <h4 className="title title-3">
                                                            Quanto você receberia mensalmente com seu imóvel alugado?
                                                        </h4>
                                                        <h4 className="title title-3 yellow">
                                                            {formatPrice(contact.priceRentalAvg)}
                                                        </h4>
                                                    </div>
                                                    <div className="item">
                                                        <div className="step">2</div>
                                                        <h4 className="title title-3">
                                                            Quanto custa, por mês, manter seu imóvel desocupado?
                                                        </h4>
                                                        <h4 className="title title-3 red">
                                                            - {formatPrice(monthlyCost)}
                                                        </h4>
                                                        <p className="text text-6">
                                                            *Esse é o valor que o seu imóvel tem de custo fixo mensal. Quando ele está alugado, essas despesas ficam por conta do inquilino.
                                                        </p>
                                                    </div>
                                                    <div className="item">
                                                        <div className="step">2</div>
                                                        <h4 className="title title-3">
                                                            Resultado Final. Quanto você poderia estar ganhando em um ano se o seu imóvel estivesse locado?
                                                        </h4>
                                                        <div className="values">
                                                            <div className="block">
                                                                <p className="title title-3">
                                                                    {formatPrice(yearlyRental)} <span>+</span>
                                                                </p>
                                                                <p className="text text-6">
                                                                    Valor anual recebido em aluguéis
                                                                </p>
                                                            </div>
                                                            <div className="block">
                                                                <p className="title title-3">
                                                                    {formatPrice(yearlyCost)} <span>=</span>
                                                                </p>
                                                                <p className="text text-6">
                                                                    Economia anual com as despesas do imóvel
                                                                </p>
                                                            </div>
                                                            <div className="block">
                                                                <p className="title title-1">
                                                                    {formatPrice(yearlyBalance)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="block-actions">
                                                <h4 className="title title-2">Deixe seu imóvel para alugar na Anagê!</h4>
                                                <RouterLink to="/anuncie-seu-imovel" className="btn btn-1">Anunciar com a Anagê</RouterLink>
                                                <a href={getWhatsappLink("47999680934")} target="_blank" className="btn btn-whats"
                                                    onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', '47999680934') }}
                                                >
                                                    <img alt="" src={whatsapp} className="icon"></img>
                                                    Fale pelo WhatsApp
                                                </a>
                                                <RouterLink to="/calculadora-de-aluguel" className="btn btn-back">
                                                    <img alt="" src={refresh} className="icon"></img>
                                                    Refazer cálculo
                                                </RouterLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="info">
                                                <p className="text text-6">
                                                    * Esse é o valor do rendimento mensal que o seu imóvel pode gerar se estiver alugado. Não foram contabilizadas as despesas fixas que, nesse caso, serão pagas pelo inquilino.
                                                </p>
                                                <p className="text text-6">
                                                    ** Para fazer este cálculo foram considerados o tipo, bairro, área privativa e idade do imóvel.
                                                </p>
                                                <p className="text text-6">
                                                    *** Outros fatores podem interferir no valor estimado como o estado do imóvel, a presença e o estado da mobília, a área do terreno e também as características da vizinhança.
                                                </p>
                                                <p className="text text-6">
                                                    O valor é estimado, fale com nossos consultores para uma avaliação profissional precisa.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <Newsletter />
        </Page>
    );
}

export default FindProperty;
