/* eslint-disable react/no-array-index-key */
import React, {
    Suspense,
    Fragment
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import Home from 'src/views/pages/Home';
import AboutPage from 'src/views/pages/AboutPage';
import FindProperty from 'src/views/pages/FindProperty';
import Broker from 'src/views/pages/Brokers/Details';
import FavoriteProperties from 'src/views/pages/FavoriteProperties';
import RentalCalculator from 'src/views/pages/RentalCalculator';
import AdvertiseProperty from 'src/views/pages/AdvertiseProperty';
import RentalCalculatorResult from 'src/views/pages/RentalCalculator/Result';
import SimulateFinancing from 'src/views/pages/SimulateFinancing';
import HowRent from 'src/views/pages/HowRent';
import HowBuy from 'src/views/pages/HowBuy';
import Contact from 'src/views/pages/Contact';
import Faq from 'src/views/pages/Faq';
import Success from 'src/views/pages/Success';
import CustomerArea from 'src/views/pages/CustomerArea';
import OwnerArea from 'src/views/pages/CustomerArea/Owner';
import TenantArea from 'src/views/pages/CustomerArea/Tenant';
import TenantAreaMaintenance from 'src/views/pages/CustomerArea/Tenant/Maintenance';
import City from 'src/views/pages/City';
import PrivacyPolicy from 'src/views/pages/PrivacyPolicy';
import RealEstate from 'src/views/pages/RealEstate';
import ShowCase from 'src/views/pages/ShowCase/ListPosts';
import ShowCaseDetails from 'src/views/pages/ShowCase/Details/index';
import ShowCaseCategoryDetails from 'src/views/pages/ShowCase/DetailsCategory/index'
import PageNotFound from 'src/views/pages/PageNotFound';
import BlogList from 'src/views/pages/Blog/List';
import BlogPost from 'src/views/pages/Blog/Post';
import LoadingScreen from 'src/components/LoadingScreen';

const routesConfig = [
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/',
                component: Home
            },
            {
                exact: true,
                path: '/encontrar-meu-imovel',
                component: FindProperty
            },
            {
                exact: true,
                path: '/institucional',
                component: AboutPage
            },
            {
                exact: true,
                path: '/corretores/:slug',
                component: Broker
            },
            {
                exact: true,
                path: '/imoveis-favoritos',
                component: FavoriteProperties
            },
            {
                exact: true,
                path: '/anuncie-seu-imovel',
                component: AdvertiseProperty
            },
            {
                exact: true,
                path: '/calculadora-de-aluguel',
                component: RentalCalculator
            },
            {
                exact: true,
                path: '/calculadora-de-aluguel/resultado',
                component: RentalCalculatorResult
            },
            {
                exact: true,
                path: '/como-comprar',
                component: HowBuy
            },
            {
                exact: true,
                path: '/como-alugar',
                component: HowRent
            },
            {
                exact: true,
                path: '/financiamento',
                component: SimulateFinancing
            },
            {
                exact: true,
                path: '/contato',
                component: Contact
            },
            {
                exact: true,
                path: '/perguntas-frequentes',
                component: Faq
            },
            {
                exact: true,
                path: '/obrigado',
                component: Success
            },
            {
                exact: true,
                path: '/area-do-cliente',
                component: CustomerArea
            },
            {
                exact: true,
                path: '/area-do-cliente/sou-proprietario',
                component: OwnerArea
            },
            {
                exact: true,
                path: '/area-do-cliente/sou-inquilino',
                component: TenantArea
            },
            {
              exact: true,
              path: '/area-do-cliente/sou-inquilino/manutencao',
              component: TenantAreaMaintenance
            },
            {
                exact: true,
                path: [
                    '/joinville',
                    '/joinville/:neighbourhood'
                ],
                component: City
            },
            {
                exact: true,
                path: '/politica-de-privacidade',
                component: PrivacyPolicy
            },
            // {
            //     exact: true,
            //     path: '/resultados-de-busca',
            //     component: SearchResult
            // },
            {
                exact: true,
                path: '/mensagem-enviada-com-sucesso',
                component: Success
            },
            // {
            //     exact: true,
            //     path: '/blog',
            //     component: ShowCase
            // },
            // {
            //     exact: true,
            //     path: '/blog/:slug',
            //     component: ShowCaseDetails
            // },
            // {
            //     exact: true,
            //     path: '/blog/categorias/:slug',
            //     component: ShowCaseCategoryDetails
            // },
            {
                exact: true,
                path: [
                    '/blog',
                    '/blog/page/:page',
                    '/blog/categorias/:categorySlug',
                    '/blog/tag/:tagSlug',
                    '/blog/categorias/:slug/page/:page'
                ],
                component: BlogList
            },
            {
                exact: false,
                path: '/blog/:slug',
                component: BlogPost
            },
            {
                exact: false,
                component: RealEstate
            }
        ]
    }
];

const renderRoutes = (routes) => (routes ? (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
) : null);

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;
