import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAnalytics } from 'src/hooks/useAnalytics';
//import logo from 'src/assets/images/logo-anage-menu-white.svg';
import logo from 'src/assets/images/anage-select-branco.svg';
import whatsapp from 'src/assets/images/icons/whatsapp.svg';
import linkedin from 'src/assets/images/icons/linkedin-white.svg';
import facebook from 'src/assets/images/icons/facebook-white.svg';
import instagram from 'src/assets/images/icons/instagram-white.svg';
import youtube from 'src/assets/images/icons/youtube-white.svg';

import { getWhatsappLink } from 'src/utils/functions';
import whatsappWhite from 'src/assets/images/icons/whatsapp-white.svg';
import avatar from 'src/assets/images/broker.jpeg';

function Footer({ className }) {
    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const brokerInformation = useSelector((state) => state.interface.brokerInformation);
    const { trackEvent } = useAnalytics();

    return (
        <div>
            <footer className="footer">
                <div className="container">
                    <div className="content">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="block">
                                            <div className="logo">
                                                <img alt="" src={logo} className="img-logo"></img><br/>
                                            </div>
                                            <ul className="social-media">
                                                <li className="list-item">
                                                    <a href="https://www.linkedin.com/company/anage-imoveis-ltda/" target="_blank" className="list-link linkedin">
                                                        <img alt="" src={linkedin} className="icon"></img>
                                                    </a>
                                                </li>
                                                <li className="list-item">
                                                    <a href="https://www.instagram.com/anageselect/" target="_blank" className="list-link instagram">
                                                        <img alt="" src={instagram} className="icon"></img>
                                                    </a>
                                                </li>
                                                <li className="list-item">
                                                    <a href="https://www.facebook.com/anageselect/" target="_blank" className="list-link facebook">
                                                        <img alt="" src={facebook} className="icon"></img>
                                                    </a>
                                                </li>
                                                <li className="list-item">
                                                    <a href="https://www.youtube.com/user/anagetv" target="_blank" className="list-link youtube">
                                                        <img alt="" src={youtube} className="icon"></img>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="block-address">
                                            <p className="text">
                                                Onde estamos
                                            </p>
                                            <p className="text text-white">
                                                Rua XV de Novembro, 1165 <br /> América - Joinville (SC)
                                            </p>
                                        </div>
                                        <div className="block-contact">
                                            <h4 className="title title-3">47 3025-3002</h4>
                                            <a href={getWhatsappLink("47999680934")} target="_blank" style={{display: 'flex'}}
                                              onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', '47999680934') }}
                                            >
                                                <h4 className="title title-3" style={{marginRight: '5px'}}>47 99968-0934</h4>
                                                <img alt="" src={whatsapp} className="icon" style={{marginTop: '6px'}}></img>
                                            </a>
                                            {/* <h4 className="title title-3">47 99968-0934</h4>
                                            <img alt="" src={whatsapp} className="icon"></img> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="info">
                                    <p className="text">
                                        <span>2024 © Anagê Imóveis. Todos os direitos reservados &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; </span><RouterLink to="/politica-de-privacidade">Política de Privacidade</RouterLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


            {brokerInformation ? (
                <div className="broker-floating">
                  <RouterLink to={"/corretores/" + brokerInformation.broker.slug}>
                    <div className="broker-header">
                        <div className="broker-image" style={{ backgroundImage: 'url(' + brokerInformation.broker.photo + ')' }}></div>
                        <div className="broker-name">{brokerInformation.broker.name}</div>
                    </div>
                  </RouterLink>
                  {brokerInformation.broker.cellphone && (
                      <a href={getWhatsappLink(brokerInformation.broker.cellphone)} target="_blank" className="btn btn-whats"
                          onClick={() => { trackEvent('Clique no botão', 'Whatsapp Corretor', brokerInformation.broker.cellphone) }}
                      >
                          <img alt="" src={whatsapp} className="icon"></img>
                          Fale pelo WhatsApp
                      </a>
                  )}
                </div>
            ) : (
                <>
                    <a href={getWhatsappLink("47999680934")} target="_blank" className="btn btn-whats-floating"
                     onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', '47999680934') }}
                    >
                        <img alt="" src={whatsappWhite} className="icon"></img>
                    </a>
                    {/* <div id="zoe-bot"></div> */}
                </>
            )}


        </div>
    );
}

Footer.propTypes = {
    className: PropTypes.string
};

export default Footer;
