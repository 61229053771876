import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAnalytics } from 'src/hooks/useAnalytics';
import img from 'src/assets/images/1_4.png';

import { getWhatsappLink } from 'src/utils/functions';
import ManagerApi from 'src/services/managerApi';

export default function SecondaryBanner() {
    const generalInformation = useSelector((state) => state.interface.generalInformation);
    const { trackEvent } = useAnalytics();
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        let newBanners = []
        generalInformation.banners.map( (banner) => {
            if(banner.type == 'home') {
                newBanners.push(banner)
            }
        });
        setBanners(newBanners);
    }, []);

    return (
        <div className="secondary-banner">
            <div className="container">
                <div className="item" style={{ backgroundImage: 'url(' + img + ')' }}>
                    <div className="row">
                        <div className="col-md-5 offset-md-2">
                            <p className="text text-1 text-yellow">
                                Atendimento único
                            </p>
                            <h4 className="title title-10">
                                VOCÊ DESEJA, NÓS AJUDAMOS A REALIZAR
                            </h4>
                        </div>
                        <div className="col-md-5">
                            <p className="text text-1">
                                Aplicamos nosso know-how de mais de 30 anos de experiência no mercado imobiliário para potencializar a conquista dos seus sonhos.
                            </p>
                            <a href={getWhatsappLink("47999680934")} target="_blank" className="btn btn-1"
                                onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', '47999680934') }}
                            >
                                Solicitar atendimento
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}